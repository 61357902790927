import { KEY_HEADER_TOKEN, REACT_APP_URL_API } from "../util/const"
import { getToken } from "./keycloak.service"

type Method = "GET" | "POST" | "PUT" | "DELETE"

// function handleErrorRequest(response: any, code_error: number) {
//     const error = { error: true }
//     let errorMessages = ""

//     if (code_error === 404)
//         return { ...error, errorMessages: "Not found" }

//     if (response.errors?.length) {
//         for (let i = 0; i < response.errors.length; i++) {
//             errorMessages += `field: ${response.errors[i].path}, ${response.errors[i].msg} \n`
//         }
//         return { ...error, errorMessages }
//     }

//     return { ...error, errorMessages: response.errorMessages }
// }

type iSignal = AbortSignal | null | undefined

type SendRequestTypes = {
    method: Method,
    token?: string,
    url: string,
    params?: string,
    body?: null | any,
    tokenRequired?: boolean
    signal?: AbortSignal | null | undefined
    formData?: any
}

async function sendRequest({ method = "GET", url = "", body = null, params = "", tokenRequired = true, signal = undefined, formData = undefined }: SendRequestTypes) {
    let options: any = { signal }
    try {
        let token = ''

        if (tokenRequired) {
            token = await getToken() || ''
            if (!token) throw Error('Token invalid')
        }

        if (token && token !== "")
            options = {
                ...options,
                headers: {
                    [KEY_HEADER_TOKEN]: `${token}`
                }
            }
        if (method !== "GET" && body) {
            options = {
                ...options,
                body: JSON.stringify(body),
                headers: {
                    ...options.headers,
                    "Content-Type": " application/json",
                }
            }
        }
        if (formData) {
            options = {
                ...options,
                body: formData
            }
        }
        options = { ...options, method: method }

        const parameters = params.length !== 0 ? `/${params}` : ""
        const URL = `${REACT_APP_URL_API}/${url}${parameters}`
        const response = await fetch(URL, options)

        // if (!response.ok) throw new Error('ExceptionNetworkNotResponse') //Network response was not ok

        // const status = response.status

        const data = await response.json()

        // if (status !== 200 && status !== 201 && status !== 202 && status !== 404) {
        //     delete data?.error?.innerError
        //     return handleErrorRequest(response, status)
        // }
        return data
    } catch (e: any) {
        console.log(e);
        return { error: true, message: e.message }
    }
}

export default class Service {

    // * Ticket
    static async getTicketByCodeTicket(body: any) {
        return await sendRequest({ method: "POST", url: "ticket", tokenRequired: false, body })
    }

    // * Corporations 
    static async getAllCenter(limit: number, skip: number, option = "pagination", signal?: iSignal): Promise<any> {
        let typeValidate = ''
        if (option === "select") {
            typeValidate = "&option=select"
        }
        return await sendRequest({
            method: "GET", url: `rma_center?limit=${limit}&skip=0${skip}${typeValidate}`, signal
        })
    }
    // * Corporations by id
    static async getCorporationsById(id: number,): Promise<any> {
        return await sendRequest({
            method: "GET", url: `rma_center`, params: id + ''
        })
    }

    // PostRMA
    static async getRmaManagerByIdUserAuth(limit: number, skip: number, option: string = '', signal?: iSignal): Promise<any> {
        return await sendRequest({
            method: "GET", url: `rma?limit=${limit}&skip=0${skip}`, signal
        })
    }
    static async getRmaManagerId(id: number, signal?: AbortSignal | null | undefined): Promise<any> {
        return await sendRequest({
            method: "GET", url: `rma`, params: id + '', signal
        })
    }
    static async postRmaPost(formData: FormData): Promise<any> {
        return await sendRequest({
            method: "POST", url: `rma`, formData
        })
    }
    static async putRmaPost(body: any): Promise<any> {
        return await sendRequest({
            method: "PUT", url: `rma`, body
        })
    }
    static async getCorporationsPosts(limit: number, skip: number, option: string = '', signal?: iSignal): Promise<any> {
        return await sendRequest({
            method: "GET", url: `rma/corporation?limit=${limit}&skip=0${skip}${option}`, signal
        })
    }

    // Product
    static async getProductBySerialNumber(params: string, signal?: AbortSignal | null | undefined) {
        return await sendRequest({
            method: "GET", url: `product`, params, signal
        })
    }

    // Directions
    static async getDirectionsByIdUser(limit?: number, skip?: number, option?: string, signal?: iSignal) {
        return await sendRequest({
            method: "GET", url: `v1/customers_address/rma_ca`, signal
        })
    }

    static async postDirectionByIdUser(body: any) {
        return await sendRequest({
            body, method: "POST", url: `v1/customers_address/rma_ca`
        })
    }

    // Rma status
    static async getRmaStatus() {
        return await sendRequest({
            method: "GET", url: `status_rma`
        })
    }

    //  User
    static async getAdminUsers(limit: number, skip: number, option: string = '', signal?: iSignal): Promise<any> {
        return await sendRequest({
            method: "GET", url: `user?limit=${limit}&skip=0${skip}${option}`, signal
        })
    }

    static async userValidate() {
        return await sendRequest({
            method: 'GET', url: 'user/verification'
        })
    }

    static async getUserDashboardInformation() {
        return await sendRequest({
            method: 'GET', url: 'user/dashboard'
        })
    }

    static async postUserDashboardInformation(body: any) {
        return await sendRequest({
            method: 'POST', url: 'user/dashboard', body
        })
    }

    // Print 
    static async printRMAPost(idPost: number, signal?: AbortSignal | null | undefined) {
        return await sendRequest({
            method: 'GET', url: 'print/rma_post', params: idPost + '', signal
        })
    }
    // Message 
    static async getRmaMessage(signal?: AbortSignal | null | undefined) {
        return await sendRequest({
            method: 'GET', url: 'rma_wmsg', signal, tokenRequired: false
        })
    }
    static async getAdminMessage(limit: number, skip: number, option: string = '', signal?: iSignal): Promise<any> {
        return await sendRequest({
            method: "GET", url: `rma_wmsg/admin?limit=${limit}&skip=0${skip}${option}`, signal
        })
    }
    static async postRmaMessage(body: any) {
        return await sendRequest({
            method: 'POST', url: 'rma_wmsg', body
        })
    }
    static async putRmaMessage(id: number, body: any) {
        return await sendRequest({
            method: 'PUT', url: 'rma_wmsg', body, params: id + ''
        })
    }
    static async putOptionsRmaMessage(id: number, body: any) {
        return await sendRequest({
            method: 'PUT', url: 'rma_wmsg/admin', body, params: id + ''
        })
    }
    static async deleteOptionsRmaMessage(id: number) {
        return await sendRequest({
            method: 'DELETE', url: 'rma_wmsg/admin', params: id + ''
        })
    }
    // CUSTOMER
    static async postRMACustomers(body: { customer_code: string, id_user: number }) {
        return await sendRequest({
            method: 'POST', url: 'customer', body
        })
    }
    static async putRMACustomers(body: { customer_code: string, id_user: number, id_customer: number }) {
        return await sendRequest({
            method: 'PUT', url: 'customer', body
        })
    }
    // UPLOAD
    static async postUpload(formData: FormData) {
        return await sendRequest({
            method: 'POST', url: 'upload', formData
        })
    }
    static async deleteFile(uid: string) {
        return await sendRequest({
            method: 'DELETE', url: 'upload', params: uid
        })
    }
    static async deleteFileById(id: number) {
        return await sendRequest({
            method: 'DELETE', url: 'rma/attachment', params: id + ''
        })
    }
    static async uploadFileByRmaId(id: number, formData: FormData) {
        return await sendRequest({
            method: 'POST', url: 'rma/attachment', params: id + '', formData
        })
    }

    // ADMIN
    static async getRmaAdminManager(limit: number, skip: number, option: string = '', signal?: iSignal): Promise<any> {
        return await sendRequest({
            method: "GET", url: `admin/rma?limit=${limit}&skip=0${skip}${option}`, signal
        })
    }

}

