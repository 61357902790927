import { useEffect, useState } from "react";

import { Field, Textarea, Button, Input, Checkbox, Title2 } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import useToast from "../../../hook/useToast";
import Service from "../../../service/sendRequest";
import { NotificationMessage } from "../../../util/types";
import { dateToString } from "../../../util/util";


export default function AdminMessageCreate({ selectedMessage, close }: { close: () => void, selectedMessage: NotificationMessage }) {
    const { notify, NotifyComponent } = useToast()
    const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>(selectedMessage)
    const [loading, setLoading] = useState(false)

    const onChange = (event: any) => {
        const { value, name } = event.target
        if (name === 'active')
            return setNotificationMessage((prev) => { return { ...prev, active: !prev.active } })

        setNotificationMessage((prev) => { return { ...prev, [name]: value } })
    }
    const onSubmit = async (event: any) => {
        event.preventDefault()
        if (!notificationMessage.date_start || !notificationMessage.date_end) return
        setLoading(() => true)
        try {
            let response;
            const data = {
                message: JSON.stringify({
                    en: notificationMessage.en,
                    it: notificationMessage.it,
                    de: notificationMessage.de
                }),
                link: notificationMessage.link || null,
                active: notificationMessage.active,
                date_start: dateToString(notificationMessage.date_start as Date),
                date_end: dateToString(notificationMessage.date_end as Date)
            }
            if (selectedMessage.id === -1) {
                response = await Service.postRmaMessage(data)
            } else {
                response = await Service.putRmaMessage(selectedMessage.id, data)
            }
            if (!response.status) throw Error('ExceptionBadRequest')
            notify({
                intent: "success",
                title: 'Save Successful',
            })
            setTimeout(() => {
                close()
                // setLoading(() => false)
            }, 1000)
        } catch (error) {
            setLoading(() => false)
            notify({
                intent: "error",
                title: 'Error',
            })
        }
    }

    const onSelectDate = (date: Date | null | undefined, name: string) => {
        // console.log('form: ', dateToString(date as Date))
        setNotificationMessage((prev) => { return { ...prev, [name]: date } })
    }

    useEffect(() => {
        if (selectedMessage.id === -1) return
        setNotificationMessage(() => selectedMessage)
    }, [selectedMessage])

    return (
        <>
            <form
                onSubmit={onSubmit}
                className={` ${loading && 'cursor-pointer'} flex flex-col items-center gap-2 py-10`}
            >
                <div className="w-full flex justify-between items-center">
                    <Title2 className="w-full ">{selectedMessage?.id === -1 ? 'Create' : 'Update'} a new Web Message</Title2>
                    <Button onClick={() => close()} > Back  </Button>
                </div>
                <Field className="w-full" label="Message in English" required>
                    <Textarea disabled={loading} value={notificationMessage.en} name="en" onChange={onChange} />
                </Field>
                <Field className="w-full" label="Message in Italian" required>
                    <Textarea disabled={loading} value={notificationMessage.it} name="it" onChange={onChange} />
                </Field>
                <Field className="w-full" label="Message in German" required>
                    <Textarea disabled={loading} value={notificationMessage.de} name="de" onChange={onChange} />
                </Field>
                <Field className="w-full" label="Link">
                    <Input disabled={loading} maxLength={150} type="url" value={notificationMessage.link} name="link" onChange={onChange} />
                </Field>
                <div className="w-full flex flex-col sm:flex-row gap-2">
                    <Field required className="w-full sm:w-1/2" label="Start Date">
                        <DatePicker
                            disabled={loading}
                            value={notificationMessage.date_start}
                            onSelectDate={(date) => onSelectDate(date, 'date_start')}
                            name="date_start"
                            placeholder="Select a date..."
                        />
                    </Field>
                    <Field required className="w-full sm:w-1/2" label="End Date">
                        <DatePicker
                            disabled={loading}
                            value={notificationMessage.date_end}
                            onSelectDate={(date) => onSelectDate(date, 'date_end')}
                            name="date_end"
                            placeholder="Select a date..."
                        />
                    </Field>
                </div>
                <div className="py-5 w-full gap-5 flex items-center justify-start ">
                    <span className="text-lg">Activate</span>
                    <Checkbox disabled={loading} checked={notificationMessage.active} name="active" onChange={onChange} />
                </div>
                <Button disabled={loading} type="submit" className="w-full md:w-1/2" appearance="primary">Save</Button>
            </form>
            {NotifyComponent}
        </>
    )
}