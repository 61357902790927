
export default function ManagerCreateSkeleton() {

    return (
        <>
            <div className="col-span-4  sm:col-span-1 w-full" >
                <div className="border bg-slate-300 w-1/3 h-5 mb-3 rounded-md" />
                <div className="border bg-slate-300 h-9 rounded-md" />
            </div>
            <div className="col-span-4  sm:col-span-3 h-28" >
                <div className="border bg-slate-300 w-[100px] h-5 mb-3 rounded-md" />
                <div className="border bg-slate-300 h-9 rounded-md" />
            </div>
            <div className="col-span-4 sm:col-span-2 lg:col-span-1 w-full mt-6" >
                <div className="border bg-slate-300 w-1/2 h-5 mb-3 rounded-md" />
                <div className="border bg-slate-300 h-9 rounded-md" />
            </div>
            <div className="col-span-4 sm:col-span-1 lg:col-span-1 w-full mt-6" >
                <div className="border bg-slate-300 w-1/5 h-5 mb-3 rounded-md" />
                <div className="border bg-slate-300 h-9 rounded-md" />
            </div>
            <div className="col-span-4 sm:col-span-2 lg:col-span-1 w-full mt-6" >
                <div className="border bg-slate-300 w-[80%] h-5 mb-3 rounded-md" />
                <div className="border bg-slate-300 h-9 rounded-md" />
            </div>
            <div className="col-span-4 sm:col-span-2 lg:col-span-1 w-full mt-6" >
                <div className="border bg-slate-300 w-[80%] h-5 mb-3 rounded-md" />
                <div className="border bg-slate-300 h-9 rounded-md" />
            </div>
            <div className="col-span-4 sm:col-span-2 w-full mt-6" >
                <div className="border bg-slate-300 w-1/5 h-5 mb-3 rounded-md" />
                <div className="border bg-slate-300 h-44 .h-9 rounded-md" />
            </div>

            <div className="col-span-4 sm:col-span-2 flex flex-col w-full">
                <div>
                    <div className='col-span-4 sm:col-span-2 mt-6 mb-2' >
                        <div className="border bg-slate-300 w-1/5 h-5 mb-3 rounded-md" />
                        <div className="border bg-slate-300 h-9 rounded-md" />
                    </div>
                </div>
                <div>
                    <div className="border bg-slate-200 w-full h-8 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-24 rounded-md" />
                </div>
            </div>
            <hr className="col-span-4" />
            <div className="col-span-4 opacity-70 mt-6" >
                <div className="border bg-slate-300 w-1/12 h-5 mb-3 rounded-md" />
                <div className="border bg-slate-300 h-[190px] rounded-md " />
            </div>
        </>
    )
}
