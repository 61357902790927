import type { EffectCallback } from "react"
import { useEffect, useRef } from "react"

type List = readonly unknown[]

export function useOnMountUnsafe(effect: EffectCallback, deps: List = []) {
    const initialized = useRef(false)

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            effect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps])
}