import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useClickOut from "../../../hook/useClickOut";
import { useToast } from "../../../context/toast.context";
import { KEY_LOCAL_STORAGE } from "../../../util/const";
// import { MdLanguage } from "react-icons/md";
import iconLang from "../../../assets/image/globe.png"
const languages = [
    { code: "en", lang: "English" },
    { code: "it", lang: "Italian" },
    { code: "de", lang: "Deutsche" },
    { code: "es", lang: "Español" },
]

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const { ref, isOpen, setIsOpen } = useClickOut()
    const { success } = useToast()

    useEffect(() => {
        document.body.dir = i18n.dir();
    }, [i18n, i18n.language])

    const changeLanguage = (lang: any) => {
        i18n.changeLanguage(lang);
        localStorage.setItem(KEY_LOCAL_STORAGE, lang)
        success({})
        setIsOpen(() => false)
    };

    return (
        <div className="relative px-2">
            <button
                onClick={() => setIsOpen(() => true)}
                className={`text-sm sm:text-base font-light flex gap-1 items-center justify-center uppercase z-50 ${isOpen && "opacity-50"}`}>
                <span>{i18n.language}</span>
                {/* <MdLanguage /> */}
                <img src={iconLang} className="w-[20px] h-[20px] " alt="lang-icon"/>
            </button>
            <div ref={ref} className={` ${isOpen && "animate-pulse-fade-in"}  animate-duration-faster z-50 fade-in absolute top-8 right-0 shadow-md w-32 flex gap-0 flex-col bg-white rounded-b-sm`}>
                {isOpen &&
                    languages.map((lng) => {
                        return (
                            <button
                                className={`text-sm sm:text-base rounded-sm text-left ${lng.code === i18n.language && "bg-customColor-exor_blue/80 text-gray-50"} text-black px-2 hover:bg-customColor-exor_blue/60 active:bg-customColor-exor_blue/95 hover:text-white cursor-pointer border-s`}
                                // className={lng.code === i18n.language ? "selected" : ""}
                                key={lng.code}
                                onClick={() => changeLanguage(lng.code)}
                            >
                                {lng.lang}
                            </button>
                        )
                    })}
            </div>
        </div>
    );
};

export default LanguageSelector;