import { useMemo } from 'react';
import { Drawer, Form, Input, Select } from 'antd';
import Service from '../../../service/sendRequest';
import useFetch from '../../../hook/useFetch';
import ManagerViewSkeleton from './manager-view-skeleton';
import { iHashStatus, iRMA } from '../../../util/types';
import { MdVerified } from "react-icons/md";
import { GoUnverified } from "react-icons/go";
import ViewAttachments from '../upload-files/view/view-attachments';

const { Option } = Select;

type Props = {
    t: (key: string) => string
    onClose: () => void
    open: boolean,
    idPostSelected: number,
    hashStatus: iHashStatus,
}

const INITIAL_STATE: iRMA = {
    id: -1,
    id_rma: -1,
    address_code: '',
    customer_code: '',
    fault_description: '',
    id_rma_center: '',
    id_ts_case: '',
    item_code: '',
    item_model: '',
    create_at: '',
    update_at: '',
    rma_status_description: '',
    serial_number: '',
    status: '',
    id_status: -1,
    item_revision: '',
    customer_case_id: '',
    destination: undefined,
    verified_destination: false,
    attachments: []
}

export default function ManagerViewEdit({ t, open, onClose, idPostSelected, hashStatus }: Props) {
    const { data: postRMA, loading, } = useFetch<iRMA>(Service.getRmaManagerId, idPostSelected, INITIAL_STATE)
    const statusRMA = hashStatus[postRMA?.id_status]?.status

    return (
        <Drawer
            title={t('page.manager.view.title')}
            width={720}
            onClose={onClose}
            open={open}
            // loading={loading}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }} // extra={isView ? undefined : (<Space> <Button onClick={onClose}>Cancel</Button> <Button onClick={onClose} type="primary"> Submit  </Button> </Space>)}
            destroyOnClose
        >
            {loading ?
                < ManagerViewSkeleton t={t} />
                :
                <>
                    <Form layout="vertical" className='grid grid-cols-4 items-center gap-2 font-semibold' >
                        {postRMA.update_at !== postRMA.create_at &&
                            <div className='col-span-4 flex justify-end py-0 text-base font-normal text-gray-500'>
                                <span className='text-sm' >{t('page.manager.view.update_at')}<time className='font-medium'> {postRMA.update_at}</time></span>
                            </div>
                        }
                        <span className='col-span-3 my-4 text-lg font-bold  text-gray-900 lg:mb-6 lg:text-2xl'>{t('page.manager.view.information')}</span>
                        <span className='col-span-1 w-full text-right' ><time className='text-sm font-medium text-right'> {postRMA.create_at}</time></span>

                        <div className='col-span-2 sm:col-span-3'>
                            <Form.Item
                                initialValue={postRMA.id_rma}
                                name="id_rma"
                                label={t('page.manager.view.id_rma')} // rules={[{ required: true, message: 'Please enter user name' }]}
                            >
                                <Input className='disabled:text-gray-700' disabled />
                            </Form.Item>
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <Form.Item
                                name="status"
                                label={t('page.manager.status')}
                                initialValue={statusRMA}
                            >
                                <Input className='disabled:text-gray-700' disabled />
                            </Form.Item>
                        </div>
                        <div className='col-span-4 sm:col-span-3'>
                            <Form.Item
                                name="serial_number"
                                label={t('page.manager.view.serial_number')}
                                initialValue={postRMA.serial_number}
                            >
                                <Input className='disabled:text-gray-700' disabled />
                            </Form.Item>
                        </div>
                        <div className='col-span-4 sm:col-span-1'>
                            <Form.Item
                                name="item_code"
                                label={t('page.manager.view.item_code')}
                                initialValue={postRMA.item_code}
                            >
                                <Input className='disabled:text-gray-700' disabled />
                            </Form.Item>
                        </div>
                        <div className='col-span-4 sm:col-span-3'>
                            <Form.Item
                                name="item_model"
                                label={t('page.manager.view.item_model')}
                                initialValue={postRMA.item_model}
                            >
                                <Input className='disabled:text-gray-700' disabled />
                            </Form.Item>
                        </div>
                        <div className='col-span-4 sm:col-span-1'>
                            <Form.Item
                                name="customer_code"
                                label={t('page.manager.view.customer_code')}
                                initialValue={postRMA.customer_code}
                            >
                                <Input className='disabled:text-gray-700' disabled />
                            </Form.Item>
                        </div>
                        <div className='col-span-4 sm:col-span-2'>
                            <Form.Item
                                name="id_ts_case"
                                label={t('page.manager.view.id_ts_case')}
                                initialValue={postRMA.id_ts_case}
                            >
                                <Input className='disabled:text-gray-700' disabled />
                            </Form.Item>
                        </div>
                        <div className='col-span-4 sm:col-span-2'>
                            <Form.Item
                                name="customer_case_id"
                                label={t('page.manager.view.customer_case_id')}
                                initialValue={postRMA.customer_case_id + ''}
                            >
                                <Input className='disabled:text-gray-700' disabled />
                            </Form.Item>
                        </div>
                        {postRMA.rma_status_description &&
                            <div className=" col-span-4">
                                <Form.Item
                                    name="rma_status_description"
                                    label={t('page.manager.view.rma_status_description')}
                                >
                                    <p className='font-normal p-2 bg-gray-100 mb-3 text-gray-900 rounded-md'    >
                                        {postRMA.rma_status_description}
                                    </p>
                                </Form.Item>
                            </div>
                        }
                        <div className='col-span-4 h-[250px]'>
                            <Form.Item
                                name="fault_description"
                                label={t('page.manager.view.fault_description')} // rules={[{ required: true, message: 'please enter url description', },]}
                                initialValue={postRMA.fault_description}
                                rootClassName='h-[250px]'
                            >
                                <Input.TextArea style={{ height: "180px", maxHeight: "180px", minHeight: "180px" }} className='disabled:text-gray-700 ' disabled rows={4}
                                // placeholder="please enter url description"
                                />
                            </Form.Item>
                        </div>
                        <div className='col-span-4' >
                            <h3 className='font-bold text-lg' >Attachments</h3>
                            <ViewAttachments attachments={postRMA.attachments} />
                        </div>
                        <div className='col-span-4' >
                            <div className="col-span-4 my-4 text-lg font-bold text-gray-900 lg:mb-6 lg:text-2xl flex justify-between items-center">
                                <h3 >{t('page.manager.view.destination')}</h3>
                                {postRMA.verified_destination ?
                                    <div className='text-base text-green-600 flex justify-center items-center'>
                                        <MdVerified />
                                        <span>{t('page.manager.destination.verified')}</span>
                                    </div>
                                    :
                                    <div className='text-base text-slate-500 flex gap-1 justify-center items-center'>
                                        <GoUnverified />
                                        <span>{t('page.manager.destination.not_verified')}</span>
                                    </div>
                                }
                            </div>
                            <Form.Item
                                className="font-semibold"
                                label={t('page.corporations.name_company')}
                            >
                                <Input disabled
                                    className="disabled:text-gray-700 font-normal"
                                    value={postRMA?.destination?.company_name}
                                />
                            </Form.Item>
                            <Form.Item
                                className="font-semibold"
                                label={t('page.manager.view.destination.address_1')}
                            >
                                <Input disabled
                                    className="disabled:text-gray-700 font-normal"
                                    value={postRMA?.destination?.address_line_1}
                                />
                            </Form.Item>
                            <Form.Item
                                className="font-semibold"
                                label={t('page.manager.view.destination.address_2')}
                            >
                                <Input disabled
                                    className="disabled:text-gray-700 font-normal"
                                    value={postRMA?.destination?.address_line_2}
                                />
                            </Form.Item>
                            <Form.Item
                                className="font-semibold"
                                label={t('page.manager.view.destination.address_3')}
                            >
                                <Input disabled
                                    className="disabled:text-gray-700 font-normal"
                                    value={postRMA?.destination?.address_line_3}
                                />
                            </Form.Item>
                            <Form.Item
                                className="font-semibold"
                                label={t('page.manager.view.destination.zip_code')}
                            >
                                <Input disabled
                                    className="disabled:text-gray-700 font-normal"
                                    value={postRMA?.destination?.zip_code}
                                />
                            </Form.Item>
                            <Form.Item
                                className="font-semibold"
                                label={t('page.manager.view.destination.city')}
                            >
                                <Input disabled
                                    className="disabled:text-gray-700 font-normal"
                                    value={postRMA?.destination?.city}
                                />
                            </Form.Item>
                            <Form.Item
                                className="font-semibold"
                                label={t('page.manager.view.destination.province')}
                            >
                                <Input disabled
                                    className="disabled:text-gray-700 font-normal"
                                    value={postRMA?.destination?.province}
                                />
                            </Form.Item>
                            <Form.Item
                                className="font-semibold"
                                label={t('page.manager.view.destination.country')}
                            >
                                <Input disabled
                                    className="disabled:text-gray-700 font-normal"
                                    value={postRMA?.destination?.country}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </>
            }
        </Drawer >
    );
};
