import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
    Button,
    Input,
    Label,
} from "@fluentui/react-components";
import { INITIAL_STATE_iUSER_DASHBOARD, iUserDashboard } from "../../../util/types";
import { useRef, useState } from "react";
import Service from "../../../service/sendRequest";
import useToast from "../../../hook/useToast";


type props = {
    userSelected?: iUserDashboard
    open: boolean,
    close: (update?: boolean) => void,
}

export default function CustomerCode({ open, close, userSelected = INITIAL_STATE_iUSER_DASHBOARD }: props) {
    const [loading, setLoading] = useState(false)
    const inputRef = useRef<any>()
    const { NotifyComponent, notify } = useToast()

    const handleSubmit = async (e: React.FormEvent) => {
        try {
            e.preventDefault();
            if (!userSelected.id) throw Error('ExceptionNotFoundUserSelected')
            const customer_code_default = (userSelected.RMA_CUSTOMER?.customer_code + '').trim()
            const customer_code = (inputRef.current.value + '').trim()

            if (customer_code === customer_code_default || customer_code === '-') return

            const body = { customer_code, id_user: parseInt(userSelected.id + '') }

            let response
            setLoading(() => true)
            if (!userSelected.RMA_CUSTOMER?.id_customer) {
                // console.log('postRMACustomers')
                response = await Service.postRMACustomers(body)
            } else {
                // console.log('putRMACustomers')
                response = await Service.putRMACustomers({ ...body, id_customer: parseInt(userSelected.RMA_CUSTOMER?.id_customer + '') })
            }

            if (!response?.status) throw Error(response.message)

            notify({ intent: 'success', title: 'Successfully' })
            setTimeout(() => {
                setLoading(() => false)
                close(true)
            }, 1000)
        } catch (error: any) {
            setLoading(() => false)
            const type = error.message
            console.log('error', error)
            if (type === 'ExceptionCustomerCodeAlreadyUse')
                return notify({ intent: 'error', title: 'Error', subtitle: 'This customer code is already use.' })

            notify({ intent: 'error', title: 'Error', subtitle: 'Unexpected error.' })
        }
    }

    return (
        <>
            <Dialog modalType="non-modal" open={open} onOpenChange={() => close()} >
                <DialogSurface className={`${loading && 'cursor-wait'}`} aria-describedby={undefined}>
                    <form onSubmit={handleSubmit}>
                        <DialogBody>
                            <DialogTitle className="pb-3">Edit Customer Code</DialogTitle>
                            <DialogContent className="flex gap-4 items-center">
                                <Label className="w-1/4" required htmlFor={"customer_code"}>
                                    Customer Code
                                </Label>
                                <Input
                                    ref={inputRef}
                                    className="uppercase w-full"
                                    required
                                    type="text"
                                    name={"customer_code"}
                                    maxLength={10}
                                    defaultValue={userSelected.RMA_CUSTOMER?.customer_code ? (userSelected.RMA_CUSTOMER?.customer_code + '').trim() : '-'}
                                />
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={loading} appearance="secondary">Close</Button>
                                </DialogTrigger>
                                <Button disabled={loading} type="submit" appearance="primary">
                                    Save
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                </DialogSurface>
            </Dialog>
            {NotifyComponent}
        </>
    )
}