import {
    Toast,
    useId,
    useToastController,
    ToastTitle,
    ToastBody,
    ToastFooter,
    Link,
    Toaster,
    ToastIntent
} from "@fluentui/react-components"

type Notify = {
    intent?: ToastIntent,
    title?: string
    subtitle?: string
    actions?: { title: string, callback?: Function }[]
}

export default function useToast() {
    const toasterId = useId("useToast");
    const { dispatchToast } = useToastController(toasterId);
    const notify = ({ actions = [], intent = 'success', subtitle, title }: Notify) => {
        return dispatchToast(
            <Toast>
                <ToastTitle
                // action={< Link > Undo </Link>}
                > {title}</ToastTitle >
                <ToastBody > {subtitle}</ToastBody>
                {actions.length !== 0 &&
                    < ToastFooter >
                        {actions.map((action, key) => {
                            return <Link key={key} onClick={() => { if (action.callback) action.callback() }}>{action.title}</Link>
                        })}
                    </ToastFooter>
                }
            </Toast>,
            { intent }
        );
    }
    return { notify, NotifyComponent: <Toaster toasterId={toasterId} /> }
}