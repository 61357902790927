import { createContext, useContext, useEffect, useState } from "react";

interface LocalConfigUser {
    v_user_config: boolean
    role: 'USER' | 'ADMIN'
    status?: string
    business?: string
}

interface LocalConfigUserContext extends LocalConfigUser {
    setConfig: Function
    loading: boolean
}

const INITIAL_VALUE: LocalConfigUser = {
    v_user_config: false,
    role: 'USER',
    status: 'required',
}

const contextLocalConfig = createContext<LocalConfigUserContext>({ ...INITIAL_VALUE, setConfig: (e: any) => { }, loading: true })

export function LocalConfigProvider({ children }: { children: React.ReactNode }) {
    const [config, setConfig] = useState<LocalConfigUser>(INITIAL_VALUE)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading && config.status !== 'required') setLoading(() => false)
    }, [config])

    return (
        <contextLocalConfig.Provider value={{ ...config, setConfig, loading }} >
            {children}
        </contextLocalConfig.Provider>
    )
}

export function useLocalConfigUser(): LocalConfigUserContext {
    const context = useContext(contextLocalConfig)
    return context
}