import { useEffect, useMemo, useState } from "react"
import { useOnMountUnsafe } from "../../../hook/useOnMountUnsafe"
import Service from "../../../service/sendRequest"
import { Link, useLocation } from "react-router-dom"

type Message = {
    id: number,
    message: string,
    link?: string
}

export default function NotificationMessage({ lang }: { lang: string }) {
    const [messages, setMessages] = useState<Message[]>([])
    const [index, setIndex] = useState(0)
    const [isExiting, setIsExiting] = useState(false)
    const { pathname } = useLocation()
    const isPathAdmin = useMemo(() => pathname.split('/')[2] === 'admin', [pathname])

    const currentMessage = useMemo(() => {
        try {
            const object = JSON.parse(messages[index].message)
            if (!object[lang]) return object['en']
            return object[lang]
        } catch (error) {
            return null
        }
    }, [index, lang, messages])

    useOnMountUnsafe(() => {
        const getData = async () => {
            const response = await Service.getRmaMessage()
            if (response.data) {
                setMessages(response.data)
                setIndex(0)
            }
        }
        if (!isPathAdmin) getData()
    })

    useEffect(() => {
        if (messages.length > 0) {
            const interval = setInterval(() => {
                setIsExiting(true)
                setTimeout(() => {
                    setIndex(prevIndex => (prevIndex + 1) % messages.length)
                    setIsExiting(false)
                }, 500)
            }, 10000)
            return () => clearInterval(interval)
        }
    }, [messages])

    return (
        <div className="pt-[64px] bg-customColor-exor_blue ">
            {messages.length === 0 || !currentMessage || isPathAdmin ?
                <></>
                :
                <div className={`relative w-full h-auto py-4 bg-customColor-exor_blue text-white text-center flex justify-center items-center `}>
                    <p className={`text-sm px-5 sm:px-0 py-1 font-medium ${isExiting ? 'animate-slide-out-fade' : 'animate-slide-up-fade'}`}>
                        {currentMessage} {' '} {messages[index].link && <Link to={`${messages[index].link}`} target="_blank" className="underline">More Information</Link>}
                    </p>
                </div>
            }
        </div>
    )
}
