import { useMemo, useState } from "react";
import AdminMessageCreate from "./message-create";
import usePagination from "../../../hook/usePagination";
import Service from "../../../service/sendRequest";
import { useTranslation } from "react-i18next";
import { NotificationMessage } from "../../../util/types";
import {
    Avatar,
    DataGridBody,
    DataGridRow,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridCell,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn,
    Title2,
    Button,
    Tooltip,
    Badge,
    Spinner,
} from "@fluentui/react-components";
import { stringToDate } from "../../../util/util";
import { IoTrashOutline } from "react-icons/io5";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import {
    EditRegular,
} from "@fluentui/react-icons";

type ItemMessage = {
    id: number
    message: string
    link?: string
    date_creation: string
    last_modify: string
    start_date: string
    end_date: string
    create_by: { name: string, email: string }
    modify_by?: { name: string, email: string }
    active: boolean
}

const INITIAL_STATE: NotificationMessage = {
    id: -1, en: '', de: '', it: '', link: '', active: false
}

export default function AdminPageMessage() {
    const { i18n: { language } } = useTranslation()
    const { data, update, loading } = usePagination<ItemMessage>(Service.getAdminMessage, 'id', 10)
    const [showCreate, setShowCreate] = useState(false)
    const [selectedMessage, setSelectedMessage] = useState<NotificationMessage>(INITIAL_STATE)


    const handleChangeViewItemMessage = async (item: ItemMessage) => {
        const { id, active } = item
        const response = await Service.putOptionsRmaMessage(id, { active })
        if (response?.status === 'ok') update()
    }

    const handleDeleteItemMessage = async (id: number) => {
        const response = await Service.deleteOptionsRmaMessage(id)
        if (response?.status === 'ok') update()
    }

    const handleSelectedMessage = (item: ItemMessage) => {
        try {
            const object = JSON.parse(item.message)
            setSelectedMessage(() => {
                return {
                    id: item.id,
                    active: item.active,
                    link: item.link,
                    de: object['de'],
                    en: object['en'],
                    it: object['it'],
                    date_start: stringToDate(item.start_date),
                    date_end: stringToDate(item.end_date)
                }
            })
            setShowCreate(() => true)
        } catch (error) {
            console.log('error: ', error)
        }
    }

    const columns: TableColumnDefinition<ItemMessage>[] = useMemo(() => [
        createTableColumn<ItemMessage>({
            columnId: "create_by",
            compare: (a, b) => {
                return a.create_by.name.localeCompare(b.create_by.name);
            },
            renderHeaderCell: () => {
                return "Create By";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout
                        media={
                            <Avatar name={item.create_by.name} />
                        }
                    >
                        <Tooltip positioning={"below"} content={item.create_by.email} relationship="label">
                            <span>{item.create_by.name}</span>
                        </Tooltip>
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<ItemMessage>({
            columnId: "message",
            compare: (a, b) => {
                return a.message.localeCompare(b.message);
            },
            renderHeaderCell: () => {
                return "Message";
            },
            renderCell: (item) => {
                try {
                    const object = JSON.parse(item.message)
                    const message = object[language] as string
                    return (
                        <p className="py-4">{message}</p>
                    );
                } catch (error) {
                    return '-'
                }
            },
        }),
        createTableColumn<ItemMessage>({
            columnId: "start_date",
            renderHeaderCell: () => {
                return "Start Data";
            },
            renderCell: (item) => {
                return item.start_date
            },
        }),
        createTableColumn<ItemMessage>({
            columnId: "end_date",
            renderHeaderCell: () => {
                return "End Data";
            },
            renderCell: (item) => {
                return item.end_date
            },
        }),
        createTableColumn<ItemMessage>({
            columnId: "active",
            renderHeaderCell: () => {
                return "Active";
            },
            renderCell: (item) => {
                return (<>
                    <Badge shape="circular" color={item.active ? "success" : "danger"} />
                </>)
            },
        }),
        createTableColumn<ItemMessage>({
            columnId: "date_creation",

            renderHeaderCell: () => {
                return "Create at";
            },
            renderCell: (item) => {
                return item.date_creation
            },
        }),
        // createTableColumn<ItemMessage>({
        //     columnId: "modify_by",

        //     renderHeaderCell: () => {
        //         return "Modified in";
        //     },
        //     renderCell: (item) => {
        //         return item.date_creation
        //     },
        // }),
        createTableColumn<ItemMessage>({
            columnId: "action",
            renderHeaderCell: () => {
                return "Action";
            },
            renderCell: (item) => {
                return (
                    <div className="flex gap-1">
                        <Button onClick={() => handleSelectedMessage(item)} icon={<EditRegular />} />
                        <Button onClick={() => handleDeleteItemMessage(item.id)} icon={<IoTrashOutline />} />
                        <Button onClick={() => handleChangeViewItemMessage(item)} icon={!item.active ? <IoEyeSharp /> : <FaEyeSlash />} />
                        {/* <Tooltip content={"Disable Message"} positioning={"below"} relationship="label"> */}
                        {/* </Tooltip> */}
                    </div>
                )
            },
        }),
    ]
        , [language, data])

    if (showCreate)
        return <AdminMessageCreate selectedMessage={selectedMessage} close={() => { setShowCreate(() => false); update() }} />

    return (
        <>
            <div className="flex justify-between items-center">
                <Title2>List Messages</Title2>
                <Button onClick={() => { setShowCreate(() => true); setSelectedMessage(() => INITIAL_STATE) }} >Create</Button>
            </div>
            <div className="overflow-x-hidden pb-[50px] min-h-[600px] ">
                {loading ? <Spinner className="py-[100px] sm:py-[200px]" label="Loading ..." />
                    :
                    <DataGrid
                        items={data}
                        columns={columns}
                        style={{ minWidth: "550px" }} // sortable // focusMode="composite"
                        selectionMode="single"
                        className="overflow-x-auto"
                    >
                        <DataGridHeader>
                            <DataGridRow
                                selectionCell={{
                                    checkboxIndicator: { "aria-label": "Select all rows" },
                                }}
                            >
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody<ItemMessage>>
                            {({ item, rowId }) => (
                                <DataGridRow<ItemMessage>
                                    key={rowId}
                                    selectionCell={{
                                        checkboxIndicator: { "aria-label": "Select row" },
                                    }}
                                >
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid>
                }
            </div>
        </>
    );
};