import { useEffect, useRef, useState } from "react"
import { useOnMountUnsafe } from "./useOnMountUnsafe"

type SendRequestType = (limit: number, skip: number, option?: string, signal?: AbortSignal | null | undefined) => Promise<any>

const INITIAL_REF_SEARCH = ' '

export default function usePagination<T>(sendRequest: SendRequestType, key: string, limit = 10, option = '') {
    const [data, setData] = useState<T[]>([])
    const [skipSearch, setSkipSkipSearch] = useState(0)
    const [skip, setSkip] = useState(0)
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [updateCount, setUpdateCount] = useState(0)
    const refSearch = useRef(INITIAL_REF_SEARCH)
    const refAbortController = useRef<AbortController>()

    const handleAbortSignal = () => {
        if (refAbortController.current)
            refAbortController.current.abort()
    }

    const dataListMap = (array = []) => {
        return array.map((item: any) => {
            return { ...item, key: item[key] }
        })
    }

    const getInitialData = async () => {
        // if (refSearch.current === `${limit}${skip}${option}`) return
        if (loading) handleAbortSignal()
        setLoading(() => true)
        refAbortController.current = new AbortController()
        let skip = skipSearch
        if (refLimit.current !== limit) {
            setSkip(() => 0)
            setSkipSkipSearch(() => 0)
            skip = 0
            refLimit.current = limit
        }
        const res = await sendRequest(limit, skip, option, refAbortController.current.signal)
        refSearch.current = option
        if (res?.data) {
            setData(dataListMap(res.data))
            setTotal(() => res.total)
        }
        setLoading(() => false)
    }

    // initial safe
    useOnMountUnsafe(() => {
        getInitialData()
    })

    const refLimit = useRef(limit)
    useEffect(() => {
        if (refLimit.current !== limit)
            getInitialData()
    }, [limit])

    useEffect(() => {
        if (updateCount > 0)
            getInitialData()
    }, [updateCount])

    useEffect(() => {
        if (refSearch.current !== INITIAL_REF_SEARCH)
            setUpdateCount(prev => prev + 1)
    }, [option])

    const handleUpdatePagination = (skip: number) => {
        const newSkip = (skip - 1) * limit;
        // setSkip(() => newSkip)
        setSkip(() => skip)
        setSkipSkipSearch(() => newSkip)
        setUpdateCount(prev => prev + 1)
    }

    const handleUpdate = () => getInitialData()


    return {
        data,
        updatePagination: handleUpdatePagination,
        skip,
        limit,
        update: handleUpdate,
        total,
        loading
    }
}