import { useMemo } from "react"
import { NAV_PATHS } from "../../../util/const"
import { Link } from "react-router-dom"


type Props = {
    t: Function
    pathname: string
    setIsOpenMenu: Function
    authenticated?: boolean
}

export default function useRenderListPath({ t, pathname, setIsOpenMenu, authenticated = false }: Props) {
    const renderList = useMemo(() => {
        return (
            NAV_PATHS.map(({ url, target = "_parent", key, auth_required }) => {
                if (!authenticated && auth_required) return ""

                const name = t(`nav.path.${key}`)
                let updateUrl = url
                if (key === "about_us")
                    updateUrl = t("nav.path.about_us_url")
                return (
                    <div key={key} onClick={() => setIsOpenMenu(false)} className="font-medium" >
                        <Link className={`md:px-1 ${pathname === url && " md:font-bold hover:font-bold font-bold"} text-white md:text-slate-700`} target={target} to={updateUrl}>
                            <div className=" bg-customColor-exor_blue rounded-md p-5 my-1 md:p-0 md:bg-[#f0f0f2]  menu__link">
                                {name}
                            </div>
                        </Link>
                    </div>
                )
            })
        )
    }, [t, pathname, setIsOpenMenu, authenticated])

    return { renderList }
}