import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

export default function PrivacyNotice({ t }: { t: (key: string) => string }) {
    const [announcement, setAnnouncement] = useState(false)

    useEffect(() => {
        const isAccepted = window.localStorage.getItem('_config_privacy')
        if (isAccepted)
            setAnnouncement(true)
    }, [])


    const handleClick = () => {
        window.localStorage.setItem('_config_privacy', '1')
        setAnnouncement(() => true)
    }


    return (
        !announcement ?
            <div className="z-50 fixed bottom-0 bg-white border-customColor-exor_grey border-t-[1px] p-3 .rounded-sm shadow-xl w-full flex justify-between items-center py-4">
                <p className="text-customColor-exor_grey">
                    {/* This website uses cookies to enhance the user experience. */}
                    {t('privacy.message')}
                </p>
                <div className="mt-0 flex justify-end space-x-3">
                    <button>
                        <Link className="text-customColor-exor_blue" to={t('privacy.privacy_url')} target="_blank">{t('privacy.privacy')}</Link>
                    </button>
                    <button onClick={handleClick} className="bg-customColor-exor_blue text-white px-4 py-2 rounded hover:bg-customColor-exor_blue">{t('privacy.btn')}</button>
                </div>
            </div>
            :
            <></>
    )
}
