import { Button } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { Result } from "antd";

export default function NotFoundPage() {
    const { t } = useTranslation()
    return (
        // <div className="py-20">
        //     <Result
        //         status="404"
        //         title="404"
        //         subTitle={t('page.corporations.not_found')}
        //     // extra={<Button type="primary">Back Home</Button>}
        //     />
        // </div>
        <main className=" grid min-h-full bg-gray-100 place-items-center .bg-white px-6 py-24 sm:pb-[350px] lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold text-blue-600">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">{t('page.corporations.not_found')}</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to={'/'}> <Button appearance="primary">{t('page.ticket.go_back_home')}</Button></Link>
                    <Link to="#" className="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">→</span></Link>
                </div>
            </div>
        </main>
    )
}