import { useReducer } from "react"

type iActionsTypes = 'CREATE_DESTINATION' | 'ADD_FILE' | 'DELETE_FILE'

type iReducerAction = {
    type: iActionsTypes,
    payload: any
}

type iUpload = {
    files: File[]
}

const INITIAL_STATE: iUpload = {
    files: []
}

function reducer(state: iUpload, action: iReducerAction): iUpload {
    try {
        if (action.type === 'DELETE_FILE')
            return { ...state, files: state.files.filter((_element, index) => index !== action.payload) }

        if (action.type === 'ADD_FILE')
            return { ...state, files: [...state.files, ...action.payload] }

        if (action.type === 'CREATE_DESTINATION')
            return { ...state }

        return state
    } catch (error) {
        console.log('error', error);
        return state
    }
}


export default function useUpload() {
    const [upload, dispatch] = useReducer(reducer, INITIAL_STATE);
    return { upload, dispatch }
}