"use client"
import { createContext, useContext } from "react"
import { message, Modal } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";


const contextToast = createContext<any>(undefined)


export function ToastProvider({ children }: { children: React.ReactNode }) {
    const [messageApi, contextHolder] = message.useMessage()
    const [modalConfirm, contextHolderConfirmSubmit] = Modal.useModal()

    // messageApi.open({
    //     key:"example",
    //     type: 'success',
    //     content: 'Loaded!',
    //     duration: 1,
    // });
    return (
        <contextToast.Provider value={{ messageApi, modalConfirm }}>
            {contextHolderConfirmSubmit}
            {contextHolder}
            {children}
        </contextToast.Provider>
    )
}


export function useModalConfirm() {
    const { modalConfirm } = useContext(contextToast)

    const funcModalConfig = (title: string, content: string, okText: string, cancelText: string) => {
        return modalConfirm.confirm({
            title,
            icon: <ExclamationCircleOutlined />,
            content,
            okText,
            cancelText,
        })
    }
    return { funcModalConfig, modalConfirm }
}

export function useToast() {
    const { messageApi } = useContext(contextToast)
    const { t } = useTranslation()


    const error = ({ key = "1", duration = 1, content = t("toast.message.error") }) => {
        messageApi.open({ key, type: 'error', content, duration, });
    }
    const loading = ({ key = "1", duration = 1, content = t("toast.message.loading") }) => {
        messageApi.open({ key, type: 'loading', content, duration, });
    }
    const success = ({ key = "1", duration = 1, content = t("toast.message.success") }) => {
        messageApi.open({ key, type: 'success', content, duration, });
    }
    const warning = ({ key = "1", duration = 1, content = t("toast.message.warning") }) => {
        messageApi.open({ key, type: 'warning', content, duration, });
    }



    return {
        toast: messageApi as MessageInstance,
        loading,
        error,
        success,
        warning
    }
}