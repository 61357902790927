import Keycloak from "keycloak-js";
import { REACT_APP_CLIENT_ID, REACT_APP_KEYCLOAK_URL, REACT_APP_REALM } from "../util/const";

export const keycloak = new Keycloak({
    realm: REACT_APP_REALM,
    clientId: REACT_APP_CLIENT_ID,
    url: REACT_APP_KEYCLOAK_URL,
})


export async function getToken() {
    if (keycloak) {
        if (keycloak.isTokenExpired()) {
            try {
                await keycloak.updateToken(30);
            } catch (error) {
                console.error('Failed to refresh the token', error);
                logout()
                return null;
            }
        }
        return keycloak.token ?? null;
    }
    return null;
}

export function logout() {
    if (keycloak) {
        keycloak.logout();
    }
}
export function login() {
    if (keycloak) {
        keycloak.login()
    }
}