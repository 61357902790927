import { Helmet } from "react-helmet-async";

type props = {
    className?: string,
    title?: string,
    title_layout?: string,
    disableDefaultClassName?: boolean
    children?: React.ReactNode
    childrenHeader?: React.ReactNode
}

export default function LayoutPage({ title = "", title_layout = undefined, children = "", className = "", disableDefaultClassName = false, childrenHeader = undefined }: props) {
    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <main className={` ${className}   ${!disableDefaultClassName && "print:block sm:container h-auto md:h-[800px]"} `}>
                {
                    (childrenHeader || title_layout) &&
                    <header className="flex flex-col items-start sm:flex-row sm:justify-between sm:items-center md:border-b-2 border-gray-300 ">
                        <h2 className="pb-5 px-2 sm:px-0 text-4xl font-bold leading-none tracking-tight .text-blue-700 bg-clip-text text-transparent bg-gradient-to-r from-customColor-exor_grey to-50% to-customColor-exor_blue">{title_layout}</h2>
                        {childrenHeader}
                    </header>
                }
                {children}
            </main>
        </>
    )
}