import { useEffect, useRef, useState } from 'react'

type iUseFetchFunctionService = (id: number, signal: AbortSignal) => Promise<any>

export default function useFetch<T>(getService: iUseFetchFunctionService, idProp: number, initialState: T) {
    const [data, setData] = useState<T>(initialState)
    const [loading, setLoading] = useState(false)
    const refAbortController = useRef<AbortController>()

    const handleAbortController = () => {
        if (refAbortController.current)
            refAbortController.current.abort()
    }

    const getData = async () => {
        setLoading(true)
        try {
            handleAbortController()
            refAbortController.current = new AbortController()
            // const response =
            await getService(idProp, refAbortController.current.signal).then((response) => {
                if (!response?.error) {
                    setData(() => response)
                    setLoading(() => false)
                }
            })
        } catch (e: any) {
            console.log(e.message)
        } finally {
            // setLoading(false)
        }
    }

    useEffect(() => {
        if (idProp === -1 || !idProp) return

        getData()

        return () => handleAbortController()
    }, [idProp])


    return { data, setData, loading }
}