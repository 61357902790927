import { useTranslation } from "react-i18next";
import LayoutPage from "../../component/ui/layout-page-component";


export default function ArchivePage() {
    const { t } = useTranslation()

    return (
        <LayoutPage className="" title={t("page.title.archive")} title_layout={t("nav.path.archive")}>
            ...
        </LayoutPage>
    )
}