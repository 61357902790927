import { Link } from "react-router-dom"
import { iAttachments } from "../../../../util/types"
import { MdFileDownload } from "react-icons/md"
import { LuPaperclip } from "react-icons/lu"
import { useState } from "react"
import { Image } from "antd"

type props = {
    attachments: iAttachments[]
}

export default function ViewAttachments({ attachments }: props) {
    const [previewImage, setPreviewImage] = useState('')
    const [previewOpen, setPreviewOpen] = useState(false)


    const handlePreview = async (index: number) => {
        try {
            const element = attachments[index]
            const type = element.name.split('.')
            if (!['jpg', 'jpeg', 'png', 'gif', 'tiff', 'heif'].includes(type[type.length - 1])) return

            setPreviewImage(element.link)
            setPreviewOpen(true)
        } catch (error) {
            console.log('error: ', error)
        }
    }
    return (
        <div
            style={{ background: 'rgba(0, 0, 0, 0.04)' }}
            className="flex flex-col w-full py-2 gap-2 h-[120px] max-h-[120px] overflow-y-auto rounded-md mt-2 border-2">
            {attachments.map(({ name, id, link }, i) => {
                return (
                    <div className="flex items-center justify-between font-normal hover:bg-slate-100 px-2 hover:opacity-50" key={i}>
                        <div className="flex items-center  gap-1 hover:underline cursor-pointer" onClick={() => handlePreview(i)}>
                            <LuPaperclip />
                            <span>{name}</span>
                        </div>
                        <div className="flex items-center justify-center gap-1">
                            <Link to={link} download={name} target="_blank"> <MdFileDownload size={20} /> </Link>
                        </div>
                    </div>
                )
            })}
            {previewImage && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}