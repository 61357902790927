export default function DashboardFormSkeleton({ t }: { t: Function }) {
    return (
        <div className="my-5 cursor-wait max-w-[1200px]">
            <h2 className="px-2 mb-4 pb-1 text-xl font-bold leading-none tracking-tight text-gray-700 border-b-2 border-gray-200">{t('page.dashboard.user_information')}</h2>
            <div className="animate-pulse grid grid-cols-6 gap-0 sm:gap-6 px-0 sm:px-5">
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3 lg:col-span-2" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3 lg:col-span-2" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="px-5 sm:px-0 col-span-6 md:col-span-2" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 md:col-span-4" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-4 lg:col-span-2" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-4" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-2 lg:col-span-2" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 lg:col-span-3" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 md:col-span-4 lg:col-span-3 " >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 md:col-span-2" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-5 md:col-span-4 lg:col-span-4" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 md:col-span-3 lg:col-span-2" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 md:col-span-3 lg:col-span-2" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="px-5 sm:px-0 col-span-3 lg:col-span-1" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="px-5 sm:px-0 col-span-3 lg:col-span-1" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3" >
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
            </div>
            <h2 className="px-2 my-4 pb-1 text-xl font-bold leading-none tracking-tight text-gray-700 border-b-2 border-gray-300">{t('page.dashboard.address_information')}</h2>
            <div className="animate-pulse grid grid-cols-6 gap-2 px-0 sm:px-5">
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3">
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3">
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3">
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-6 sm:px-0 sm:col-span-3">
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-3 sm:px-0">
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-3 sm:px-0">
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-3 sm:px-0">
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
                <div className="pb-5 px-5 col-span-3 sm:px-0">
                    <div className="border bg-slate-300 w-1/5 h-5 mb-2 rounded-md" />
                    <div className="border bg-slate-300 h-9 rounded-md" />
                </div>
            </div>
            <div className="animate-pulse">
                <div className="border bg-customColor-exor_blue h-9 ml-3 sm:mx-0 rounded-lg w-[95%] sm:w-1/4" />
            </div>
        </div>
    )
}