import { useEffect, useMemo, useRef, useState } from "react";
import useReview from "./preview.reducer";
import { useTranslation } from "react-i18next";
import Service from "../../../../service/sendRequest";
import { Link } from "react-router-dom";
import { Image, notification } from "antd";
import { LuPaperclip } from "react-icons/lu";
import { IoCloudUpload, IoTrashOutline } from "react-icons/io5"
import { MdFileDownload } from "react-icons/md";
import { iAttachments } from "../../../../util/types"

type props = { initialAttachments: iAttachments[], ID_RMA: number }

export default function UploadPreview({ initialAttachments, ID_RMA }: props) {
    const [api, contextHolderNotification] = notification.useNotification()
    const { t } = useTranslation()

    const { state, dispatch } = useReview()
    const [previewImage, setPreviewImage] = useState('')
    const [previewOpen, setPreviewOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const initialStateRef = useRef(false)
    useEffect(() => {
        if (initialAttachments.length !== 0 && !initialStateRef.current) {
            dispatch({ type: 'INIT', payload: initialAttachments })
            initialStateRef.current = true
        }
    }, [initialAttachments])

    const handleOnChangeInput = async (event: any) => {
        if (loading) return
        const key = 'handleOnChangeInput'
        try {
            setLoading(() => true)
            const selectedImages = event.target.files;
            const formData = new FormData()
            formData.append('length', selectedImages.length + '')
            for (let i = 0; i < selectedImages.length; i++) {
                formData.append(`file_${i}`, selectedImages[i]);
            }
            const response = await Service.uploadFileByRmaId(ID_RMA, formData)
            if (response.data) dispatch({ type: 'ADD', payload: response.data })
            api.success({ message: t('page.manager.attachment.upload.success'), key, duration: 3 })
            setLoading(() => false)
        } catch (error) {
            console.log('error: ', error)
            api.error({ message: t('page.manager.attachment.upload.error'), key, duration: 3 })
        } finally {
            setLoading(() => false)
        }
    }

    const renderViewer = useMemo(() => {
        return previewImage && (
            <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
            />
        )
    }, [previewOpen])


    const handlePreview = async (index: number) => {
        if (loading) return
        try {
            const element = state.files[index]
            const type = element.name.split('.')
            if (!['jpg', 'jpeg', 'png', 'gif', 'tiff', 'heif'].includes(type[type.length - 1])) {
                // const link = document.createElement('a')
                // link.href = element.link
                // link.target = '_blank'
                // link.rel = 'noreferrer'
                // link.download = element.name
                // document.body.appendChild(link)
                // link.click();
                // document.body.removeChild(link)
                return
            }
            setPreviewImage(element.link)
            setPreviewOpen(true)
        } catch (error) {
            console.log('error: ', error)
        }
    }


    const handleDelete = async (id: number) => {
        if (loading) return
        const key = 'handleDelete'
        try {
            setLoading(() => true)
            const response = await Service.deleteFileById(id)
            if (response?.status === 'ok') {
                dispatch({ type: 'DELETE', payload: id })
                return api.success({ message: t('page.manager.attachment.delete.success'), key, duration: 3 })
            }
            api.error({ message: t('page.manager.attachment.delete.error'), key, duration: 3 })
        } catch (error) {
            console.log('error: ', error)
        } finally {
            setLoading(() => false)
        }
    }

    return (
        <>
            <div className={`${loading && 'animate-pulse cursor-wait'}`}>
                {contextHolderNotification}
                {renderViewer}
                <label form="upload-file" className={`${loading && 'opacity-90'}  cursor-pointer flex justify-center items-center gap-2 p-1 border-gray-200  ${!loading ? 'hover:border-customColor-exor_blue/80 active:border-customColor-exor_blue' : 'hover:border-customColor-exor_blue/50 active:border-customColor-exor_blue/70'}  border-2 rounded-t-md`}>
                    <IoCloudUpload className="text-customColor-exor_blue" size={30} />
                    <p className="ant-upload-text">{t('page.manager.attachment')}</p>
                    <input
                        disabled={loading}
                        onChange={handleOnChangeInput}
                        id="upload-file" type="file" multiple style={{ display: 'none' }} />
                </label>
                <div className="flex flex-col w-full py-2 gap-2 h-[120px] max-h-[120px] bg-slate-50 overflow-y-auto  rounded-b-md">
                    {state.files.map(({ name, id, link }, i) => {
                        return (
                            <div className="flex items-center justify-between font-normal hover:bg-slate-100 px-2 hover:opacity-50" key={i}>
                                <div className="flex items-center  gap-1 hover:underline cursor-pointer" onClick={() => handlePreview(i)}>
                                    <LuPaperclip />
                                    <span>{name}</span>
                                </div>
                                <div className="flex items-center justify-center gap-1">
                                    <IoTrashOutline onClick={() => handleDelete(id)} className="cursor-pointer hover:text-customColor-exor_blue" size={15} />
                                    <Link to={link} download={name} target="_blank"> <MdFileDownload size={20} /> </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}