import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import Service from "../../../service/sendRequest"
import { iFormManager } from "../../../util/types"


type props = {
    form: iFormManager,
    setForm: Dispatch<SetStateAction<iFormManager>>,
    t: (key: string) => string,
    isRequired: boolean
}

export default function useValidationSerialNumber({ form, setForm, t, isRequired }: props) {
    const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout>()
    const [serialNumber, setSerialNumber,] = useState('')
    const [serialValidation, setSerialValidation] = useState({ status: '', message: '' })
    const initialSearch = useRef(false)

    useEffect(() => {
        if (form.serial_number.trim().length !== 0)
            setSerialNumber(() => form.serial_number)
    }, [form.serial_number])


    useEffect(() => {
        if (serialNumber.length === 0) setForm((prev) => { return { ...prev, item_code: '', item_model: '' } })
        if ((form.serial_number === serialNumber && !initialSearch.current) || !isRequired) return

        initialSearch.current = true
        setForm(prev => {
            return { ...prev, item_code: '', item_model: '', isProductSerialOld: false, }
        })
        setSerialValidation(() => { return { message: '', status: '' } })
        if (currentTimeout) clearTimeout(currentTimeout)
        if (serialNumber.length === 0) return

        // if (serialNumber.length > 0) {
        setSerialValidation(prev => { return { ...prev, status: 'validating' } })
        const newTimeout = setTimeout(() => {
            if (serialNumber)
                validateSerialNumber()
        }, 4000)

        setCurrentTimeout(newTimeout)

        return () => clearTimeout(newTimeout)
        // }
    }, [serialNumber])


    const validateSerialNumber = async () => {
        try {
            const response = await Service.getProductBySerialNumber(serialNumber)

            if (response.error) throw Error(response.message)

            if (response.productIsOld) {
                setForm(prev => { return { ...prev, item_code: response.item_code, item_model: response.item_model, isProductSerialOld: true, serial_number: serialNumber } })
                return setSerialValidation(() => {
                    return { message: t('validate.serial_number.not_under_warranty').replace('@date@', response.date_shipping), status: 'error' }
                })
            }
            setForm(prev => { return { ...prev, item_code: response.item_code, item_model: response.item_model, isProductSerialOld: false, serial_number: serialNumber } })
            setSerialValidation(() => { return { message: `${t('message.data_shipping')} ${response.date_shipping}`, status: 'success' } })
        } catch (e: any) {
            const type = e.message as string
            if (type === 'ExceptionNotFound')
                setSerialValidation(() => { return { message: t('validate.serial_number.not_found'), status: 'error' } })

            setSerialValidation(prev => { return { ...prev, status: 'error' } })
        }
    }


    return { serialValidation, serialNumber, setSerialNumber, setSerialValidation }
}