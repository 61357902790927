import { useState } from "react"
import { useOnMountUnsafe } from "./useOnMountUnsafe"
import Service from "../service/sendRequest"
import { iHashStatus } from "../util/types"


export default function useRmaStatus() {
    const [hashStatus, setHashStatus] = useState<iHashStatus>({})

    useOnMountUnsafe(() => {
        const getRmaStatus = async () => {
            const response = await Service.getRmaStatus()
            if (!response?.error)
                setHashStatus(() => response.data)
        }
        getRmaStatus()
    })

    return { hashStatus }
}