import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// dictionaries
import dictionaries_en from './dictionaries/en.json'
import dictionaries_it from './dictionaries/it.json'
import dictionaries_de from './dictionaries/de.json'
import dictionaries_es from './dictionaries/es.json'
import { KEY_LOCAL_STORAGE } from "../util/const"

function getLang() {
    const lang = localStorage.getItem(KEY_LOCAL_STORAGE) as string
    return lang || "en"
}

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: dictionaries_en,
    de: dictionaries_de,
    it: dictionaries_it,
    es: dictionaries_es
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: getLang(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })

export default i18n