import { CompoundButton, Title1 } from "@fluentui/react-components";
import { GrDocumentConfig } from "react-icons/gr";
import { MdMessage } from "react-icons/md";
import { RiFileUserLine } from "react-icons/ri";
import { Link } from "react-router-dom";

export default function AdminPage({ pathname }: { pathname: string }) {
    return (
        <div className="py-[50px] sm:py-[260px] gap-5 flex flex-col items-center justify-center">
            <Title1> Administration Page</Title1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                <Link to={'/auth/admin/message'}>
                    <CompoundButton
                        className="w-full"
                        size="small"
                        appearance={'/auth/admin/message' === pathname ? "primary" : 'secondary'}
                        // secondaryContent="Secondary content"
                        icon={<MdMessage size={25} />}
                    >
                        Web Messages
                    </CompoundButton>
                </Link>
                <Link to={'/auth/admin/users'}>
                    <CompoundButton
                        className="w-full"
                        size="small"
                        appearance={'/auth/admin/users' === pathname ? "primary" : 'secondary'}
                        // secondaryContent="Secondary content"
                        icon={<RiFileUserLine size={25} />}
                    >
                        User Management
                    </CompoundButton>
                </Link>
                <Link to={'/auth/admin/rma'}>
                    <CompoundButton
                        className="w-full"
                        size="small"
                        appearance={'/auth/admin/rma' === pathname ? "primary" : 'secondary'}
                        // secondaryContent="Secondary content"
                        icon={<GrDocumentConfig size={25} />}
                    >
                        RMA Management
                    </CompoundButton>
                </Link>
            </div>
        </div>
    )
}