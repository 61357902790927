import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouterComponent from './App';
import ProviderComponent from './component/provider-component';
import './index.css';
import './i18n/i18n';
// import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ProviderComponent>
    <React.StrictMode>
      <AppRouterComponent />
    </React.StrictMode>
  </ProviderComponent>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
