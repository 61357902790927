import { BsYoutube } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa6";
import { Link } from "react-router-dom";
import EXOR_LOGO_FOOTER from "../../../assets/image/EXOR_LOGO_FOOTER.png"


export default function Footer() {
    return (
        <footer className="print:hidden flex flex-col  pb-16 py-10 bg-black text-white">
            {/* Footer-end */}
            <div className="grid gap-4 grid-cols-4 px-5 pb-4">
                <div className="col-span-4 mb-2">
                    <img src={EXOR_LOGO_FOOTER} alt="EXOR_LOGO_FOOTER" />
                </div>
                <div className="col-span-3 flex gap-4 ">
                    <Link className="w-full sm:w-auto text-sm .hover:text-gray-500" to={"https://www.exorint.com/privacy"} target="_blank"> Privacy Policy</Link>
                    <Link className="w-full sm:w-auto text-sm .hover:text-gray-500 pr-24" to={"https://www.exorint.com/cookie-policy"} target="_blank"> Cookie Policy</Link>
                </div>
                <div className="flex gap-4 justify-end text-lg sm:text-2xl">
                    <Link className=".hover:text-gray-500" to={"https://www.youtube.com/channel/UCGt9z6CwVDTjYGHMdVa0hUA?themeRefresh=1"} target="_blank"><BsYoutube /></Link>
                    <Link className=".hover:text-gray-500" to={"https://www.linkedin.com/company/exor-international"} target="_blank"><FaLinkedin /></Link>
                </div>
            </div>
            <p className="text-sm text-gray-400 pb-5 px-5 w-[80%]">
                Via Monte Fiorino 13, 37057 - San Giovanni Lupatoto (Verona) - Italia - C.F. / P.IVA (VAT Number) IT-01990710236 - N. Reg. Prod. AEE IT18050000010399 - Capitale Sociale Euro 3.213.705 i.v. - Registro Imprese di Verona: N. 01990710236 - R.E.A. Verona N. 210055  - Email: info.it@exorint.com - Email PEC: exorint@pec.exorint.com  - N. Reg. Prod. Pile: IT1870P00004845
            </p>
        </footer >
    );
}