import { iDirections } from "../../../util/types"


type iActionsTypes = 'CREATE_DESTINATION' | 'SELECT' |
    'INITIAL_DESTINATION' | 'EDIT_DESTINATION' |
    'INITIAL_LIST_DESTINATION' | 'SELECT_DESTINATION' | 'SHOW_LIST' | 'CLOSE_SHOW_LIST'
    | 'SET_VALIDATE_DESTINATION' | 'EDIT_VERIFIED_DESTINATION'

type iReducerAction = {
    id?: number
    function?: Function
    type: iActionsTypes,
    payload?: any,
}

type iAddressState = {
    show_list: boolean,
    verified: boolean,
    destination: iDirections,
    list_destination: iDirections[] | null,
    is_create: boolean
}

const INIT_STATE_DESTINATION: iDirections = {
    id: -1, address_code: '', company_name: '', address_line_1: '', address_line_2: '', address_line_3: '', zip_code: '', city: '', province: '', country: '',
}

export const INITIAL_STATE_ADDRESS: iAddressState = {
    show_list: false,
    verified: false,
    destination: INIT_STATE_DESTINATION,
    list_destination: null,
    is_create: false
}

export function reducer(state: iAddressState, action: iReducerAction): iAddressState {
    try {
        // console.log('action.type', action.type);
        // console.log('action.payload', action.payload);

        if (action.type === 'EDIT_VERIFIED_DESTINATION') {
            return { ...state, destination: { ...state.destination, verified_destination: false } }
        }
        if (action.type === 'SET_VALIDATE_DESTINATION') {
            return { ...state, verified: action.payload }
        }
        if (action.type === 'SHOW_LIST') {
            return { ...state, show_list: true }
        }
        if (action.type === 'CLOSE_SHOW_LIST') {
            return { ...state, show_list: false }
        }
        if (action.type === 'INITIAL_DESTINATION') {
            return { ...state, destination: action.payload, is_create: action.payload.verified_destination }
        }
        if (action.type === 'CREATE_DESTINATION') {
            return {
                ...state, show_list: false, is_create: true, verified: false, destination:
                {
                    ...INIT_STATE_DESTINATION,
                    verified_destination: false,
                    id: !state.verified ? state.destination.id : -1
                }
            }
        }
        if (action.type === 'INITIAL_LIST_DESTINATION') {
            return { ...state, list_destination: action.payload }
        }
        if (action.type === 'SELECT_DESTINATION') {
            return { ...state, show_list: false, is_create: false, verified: true, destination: { ...action.payload, verified_destination: true } }
        }
        if (action.type === 'EDIT_DESTINATION') {
            return {
                ...state, show_list: false, verified: false, destination:
                {
                    ...state.destination, verified_destination: false, id: !state.verified ? state.destination.id : -1,
                    [action.payload.name]: action.payload.value
                }
            }
        }

        return state
    } catch (error) {
        console.log('error', error);
        return state
    }
}