import { useState } from "react"
import { useOnMountUnsafe } from "../../hook/useOnMountUnsafe"
import { useTranslation } from "react-i18next"
import LayoutPage from "../../component/ui/layout-page-component"
import DashboardFormSkeleton from "./dashboard-form-skeleton"
import useToast from "../../hook/useToast"
import { useLocalConfigUser } from "../../context/local-config-user.context"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Input, Button, Form } from "antd"
import Service from "../../service/sendRequest"

import { MdEmail } from "react-icons/md";
import { GrOrganization } from "react-icons/gr";
import { FiSave } from "react-icons/fi"
import { MdOutlinePhone } from "react-icons/md";
import { iUserDashboard } from "../../util/types"

const { TextArea } = Input


const INITIAL_STATE = {
    name: '',
    surname: '',
    email: '',
    organization: '',
    phone: '',
    state: '',
    city: '',
    street_address: '',
    zip_code: '',
    state_1: '',
    city_1: '',
    street_address_1: '',
    zip_code_1: '',
    state_2: '',
    city_2: '',
    street_address_2: '',
    zip_code_2: '',
    vat_gst: '',
    fiscal_id: '',
    fax: '',
    email_order: '',
    email_invoices: '',
    bank: '',
    sdi: '',
    iban: '',
    payment_terms: '',
    transport_conditions: '',
    forwarder: '',
}

const styleFieldValidation = "bg-red-100 hover:bg-red-200 hover:border-red-300"

export default function DashboardPage() {
    const [form] = Form.useForm()
    const [userDashboardInformation, setUserDashboardInformation] = useState<iUserDashboard>(INITIAL_STATE)
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()

    const [searchParams] = useSearchParams()
    const { setConfig } = useLocalConfigUser()
    const navigate = useNavigate()

    const { NotifyComponent, notify } = useToast()

    useOnMountUnsafe(() => {
        const getData = async () => {
            setLoading(() => true)
            try {
                const response = await Service.getUserDashboardInformation()
                if (!response.data) return
                const data = JSON.parse(atob(response.data))
                if (!data) return

                const dashboardDataUser: { [key: string]: string } = {}
                for (let key in data) {
                    if (typeof data[key] !== 'string') continue
                    dashboardDataUser[key] = data[key].trim()
                }
                setUserDashboardInformation(() => dashboardDataUser as unknown as iUserDashboard)

                setLoading(() => false)
            } catch (error) {
                console.log('error: ', error)
            }
        }
        getData()
    })

    const onFinish = async (values: iUserDashboard) => {
        setLoading(() => true)
        await Service.postUserDashboardInformation({ ...values }).then(async (response) => {
            if (response?.status !== 'ok') {
                const errors = response.errors as { msg: string }[]
                return notify({
                    intent: "error", title: t('toast.message.error'), subtitle: `
                    ${errors.map(({ msg }) => `- ${msg} \n`)}`
                })
            }
            // if (searchParams.get('v')) {
            setConfig((state: any) => { return { ...state, v_user_config: false } })
            return navigate('/auth/manager')
            // }
            // notify({ intent: "success", title: t('toast.message.success') })
        })
        setLoading(() => false)
    }

    return (
        <LayoutPage disableDefaultClassName className="md:container pt-5 md:px-2 mx-auto" title={t("nav.path.dashboard")} title_layout={t("nav.path.dashboard")}>
            {/* <button className="text-gray-800" onClick={() => setLoading(prev => !prev)} >click</button> */}
            {!loading ?
                <Form
                    form={form}
                    name="dashboard-user"
                    onFinish={onFinish}
                    className="my-5"
                    style={{ maxWidth: 1200 }}
                    scrollToFirstError
                    autoComplete="off"
                    layout="vertical"
                >
                    {/* <Watermark content="Exor International"> */}
                    <h1 className="px-2 mb-4 pb-1 text-xl font-bold leading-none tracking-tight text-gray-700 border-b-2 border-gray-100">{t('page.dashboard.user_information')}</h1>
                    <div className=" grid grid-cols-6 gap-0 sm:gap-2 px-0 sm:px-5">
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3 lg:col-span-2"
                            name="fullName"
                            label={t('page.dashboard.full_name')}
                            initialValue={`${userDashboardInformation.name} ${userDashboardInformation.surname}`}
                        >
                            <Input disabled prefix={<MdEmail />} className="disabled:text-gray-700 disabled:bg-white disabled:border-gray-400 disabled:font-semibold" />
                            {/* <Input disabled className="disabled:text-gray-700 disabled:bg-white disabled:border-gray-400 disabled:font-semibold" /> */}
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3 lg:col-span-2"
                            name="email"
                            label={t('page.dashboard.e_mail')}
                            initialValue={userDashboardInformation.email}
                        >
                            <Input disabled prefix={<MdEmail />} className="disabled:text-gray-700 disabled:bg-white disabled:border-gray-400 disabled:font-semibold" />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 sm:px-0 col-span-6 md:col-span-2"
                            name="phone"
                            label={t('page.dashboard.phone')}
                            initialValue={userDashboardInformation.phone}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input minLength={4} maxLength={40} prefix={<MdOutlinePhone />} className={` ${(searchParams.get('v') && !userDashboardInformation.phone) && styleFieldValidation} disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 md:col-span-4"
                            name="organization"
                            label={t('page.dashboard.organization')}
                            initialValue={userDashboardInformation.organization}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input prefix={<GrOrganization />} maxLength={50} className={` ${(searchParams.get('v') && !userDashboardInformation.organization) && styleFieldValidation} disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        {/* <div className="col-span-6 sm:col-span-3 lg:col-span-4"></div> */}
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-4 lg:col-span-2"
                            name="fax"
                            label="Fax"
                            initialValue={userDashboardInformation.fax}
                        >
                            <Input maxLength={40} className=" disabled:text-gray-800 font-normal" />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3"
                            name="email_order"
                            // label={t('page.dashboard.email_order')}
                            label={'General E-mail address'}
                            initialValue={userDashboardInformation.email_order}
                            tooltip="E-mail address where to send order confirmations"
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input prefix={<MdEmail />} maxLength={50} className={` ${(searchParams.get('v') && !userDashboardInformation.email_order) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3"
                            name="email_invoices"
                            label={t('page.dashboard.email_invoices')}
                            initialValue={userDashboardInformation.email_invoices}
                            tooltip="E-mail address where to send invoices"
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input prefix={<MdEmail />} maxLength={50} className={`${(searchParams.get('v') && !userDashboardInformation.email_invoices) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} />
                        </Form.Item>

                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-4"
                            name="vat_gst"
                            label={t('page.dashboard.fiscal_code')}
                            initialValue={userDashboardInformation.vat_gst}
                            required
                            tooltip={t('page.dashboard.fiscal_code.tooltip')}
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input maxLength={20} className={` ${(searchParams.get('v') && !userDashboardInformation.vat_gst) && styleFieldValidation
                                } uppercase  disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-2 lg:col-span-2"
                            name="fiscal_id"
                            label={t('page.dashboard.fiscal_id')}
                            initialValue={userDashboardInformation.fiscal_id}
                        // required
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: "This is a required field!",
                        //     },]}
                        >
                            {/* <Input maxLength={20} className={`${(searchParams.get('v') && !userDashboardInformation.fiscal_id) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} /> */}
                            <Input maxLength={20} className={` disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 lg:col-span-3"
                            name="bank"
                            label={t('page.dashboard.bank')}
                            initialValue={userDashboardInformation.bank}
                        // required
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: "This is a required field!",
                        //     },]}
                        >
                            {/* <Input maxLength={50} className={`${(searchParams.get('v') && !userDashboardInformation.bank) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} /> */}
                            <Input maxLength={50} className={`disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 md:col-span-4 lg:col-span-3 "
                            name="iban"
                            label={t('page.dashboard.iban')}
                            initialValue={userDashboardInformation.iban}
                        // required
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: "This is a required field!",
                        //     },]}
                        >
                            {/* <Input minLength={5} maxLength={35} className={` ${(searchParams.get('v') && !userDashboardInformation.iban) && styleFieldValidation
                                } uppercase sm:focus:text-xl sm:focus:-tracking-tighter sm:hover:-tracking-tighter sm:-tracking-normal disabled:text-gray-800 font-normal`} /> */}
                            <Input minLength={5} maxLength={35} className={`  uppercase sm:focus:text-xl sm:focus:-tracking-tighter sm:hover:-tracking-tighter sm:-tracking-normal disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 md:col-span-2"
                            name="sdi"
                            label={t('page.dashboard.sdi')}
                            initialValue={userDashboardInformation.sdi}
                            // tooltip="Codice SDI per fatturazione electtronica"
                            tooltip={t('page.dashboard.tooltip.sdi')}
                        // required
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: "This is a required field!",
                        //     },]}
                        >
                            {/* <Input maxLength={10} className={` ${(searchParams.get('v') && !userDashboardInformation.sdi) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} /> */}
                            <Input maxLength={10} className={`disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-5 md:col-span-4 lg:col-span-4"
                            name="payment_terms"
                            label={t('page.dashboard.payment_terms')}
                            initialValue={userDashboardInformation.payment_terms}
                        >
                            <Input maxLength={50} className=" disabled:text-gray-800 font-normal" />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3 h-full"
                            initialValue={userDashboardInformation.transport_conditions}
                            label={t('page.dashboard.transport_conditions')}
                            name="transport_conditions">
                            <TextArea maxLength={50} className="pb-10 font-normal" />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3 h-full"
                            initialValue={userDashboardInformation.forwarder}
                            label={t('page.dashboard.forwarder')}
                            name="forwarder">
                            <TextArea maxLength={50} className="pb-10 font-normal" />
                        </Form.Item>
                        <h2 className="mb-5 py-2 px-2 sm:px-0 col-span-6 text-xl font-semibold leading-none tracking-tight text-gray-700">
                            {t('page.dashboard.legal.headquarters')}
                        </h2>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 md:col-span-3 lg:col-span-2"
                            name="street_address"
                            label={'Street'}
                            initialValue={userDashboardInformation.street_address}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input maxLength={30} className={` ${(searchParams.get('v') && !userDashboardInformation.street_address) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 md:col-span-3 lg:col-span-2"
                            name="city"
                            label={t('page.dashboard.city')}
                            initialValue={userDashboardInformation.city}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input maxLength={30} className={` ${(searchParams.get('v') && !userDashboardInformation.city) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold pl-5 pr-1 sm:px-0 col-span-3 lg:col-span-1"
                            name="zip_code"
                            label={t('page.dashboard.zip_code')}
                            initialValue={userDashboardInformation.zip_code}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input maxLength={10} className={` ${(searchParams.get('v') && !userDashboardInformation.zip_code) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold pr-5 pl-1sm:px-0 col-span-3 lg:col-span-1"
                            name="state"
                            label={t('page.dashboard.state')}
                            initialValue={userDashboardInformation.state}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input maxLength={20} className={` ${(searchParams.get('v') && !userDashboardInformation.state) && styleFieldValidation
                                } uppercase  disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        {/* <h2 className="mb-5 py-2 px-2 sm:px-0 col-span-6 text-xl font-semibold leading-none tracking-tight text-gray-700">
                            {t('page.dashboard.Administration.head')}
                        </h2>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 md:col-span-3 lg:col-span-2"
                            name="street_address_1"
                            label={'Street'}
                            initialValue={userDashboardInformation.street_address_1}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input maxLength={30} className={` ${(searchParams.get('v') && !userDashboardInformation.street_address_1) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold px-5 col-span-6 sm:px-0 md:col-span-3 lg:col-span-2"
                            name="city_1"
                            label={t('page.dashboard.city')}
                            initialValue={userDashboardInformation.city_1}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input maxLength={30} className={` ${(searchParams.get('v') && !userDashboardInformation.city_1) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold pl-5 pr-1 sm:px-0 col-span-3 lg:col-span-1"
                            name="zip_code_1"
                            label={t('page.dashboard.zip_code')}
                            initialValue={userDashboardInformation.zip_code_1}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input maxLength={10} className={` ${(searchParams.get('v') && !userDashboardInformation.zip_code_1) && styleFieldValidation
                                } disabled:text-gray-800 font-normal`} />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold pr-5 pl-1  sm:px-0 col-span-3 lg:col-span-1"
                            name="state_1"
                            label={t('page.dashboard.state')}
                            initialValue={userDashboardInformation.state_1}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field!",
                                },]}
                        >
                            <Input maxLength={20} className={` ${(searchParams.get('v') && !userDashboardInformation.state_1) && styleFieldValidation} uppercase  disabled:text-gray-800 font-normal`} />
                        </Form.Item> */}
                    </div>
                    {/* <h2 className="px-2 my-4 pb-1 text-xl font-bold leading-none tracking-tight text-gray-700 border-b-2 border-gray-100">{t('page.dashboard.address_information')}</h2> */}
                    {/* <div className=" grid grid-cols-6 gap-2 px-0 sm:px-5">
                        <Form.Item
                            className="order-2 sm:order-none  font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3"
                            name="street_address_1"
                            label={`${t('page.dashboard.street_address')} 1`}
                            initialValue={userDashboardInformation.street_address_1}
                        >
                            <Input maxLength={30} className=" disabled:text-gray-800 font-normal" />
                        </Form.Item>
                        <h2 className="col-span-7 sm:col-span-3 text-xl font-semibold leading-none tracking-tight text-gray-700 border-b-2 border-gray-100">
                            Administration and business head
                        </h2>
                        <Form.Item
                            className=" .order-6 sm:order-none font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3"
                            name="street_address_2"
                            label={`${t('page.dashboard.street_address')} 2`}
                            initialValue={userDashboardInformation.street_address_2}
                        >
                            <Input maxLength={30} className=" disabled:text-gray-800 font-normal" />
                        </Form.Item>
                        <Form.Item
                            className="order-3 sm:order-none font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3"
                            name="city_1"
                            label={`${t('page.dashboard.city')} 1`}
                            initialValue={userDashboardInformation.city_1}
                        >
                            <Input maxLength={30} className=" disabled:text-gray-800 font-normal" />
                        </Form.Item>
                        <Form.Item
                            className=" .order-7 sm:order-none font-semibold px-5 col-span-6 sm:px-0 sm:col-span-3"
                            name="city_2"
                            label={`${t('page.dashboard.city')} 2`}
                            initialValue={userDashboardInformation.city_2}
                        >
                            <Input maxLength={30} className=" disabled:text-gray-800 font-normal" />
                        </Form.Item>
                        <Form.Item
                            className="order-4 sm:order-none font-semibold px-5 col-span-3 sm:px-0"
                            name="zip_code_1"
                            label={`${t('page.dashboard.zip_code')} 1`}
                            initialValue={userDashboardInformation.zip_code_1}
                        >
                            <Input maxLength={10} className=" disabled:text-gray-800 font-normal" />
                        </Form.Item>
                        <Form.Item
                            className=" .order-8 sm:order-none font-semibold px-5 col-span-3 sm:px-0"
                            name="zip_code_2"
                            label={`${t('page.dashboard.zip_code')} 2`}
                            initialValue={userDashboardInformation.zip_code_2}
                        >
                            <Input maxLength={10} className=" disabled:text-gray-800 font-normal" />
                        </Form.Item>
                        <Form.Item
                            className="order-5 sm:order-none font-semibold px-5 col-span-3 sm:px-0"
                            name="state_1"
                            label={`${t('page.dashboard.state')} 1`}
                            initialValue={userDashboardInformation.state_1}
                        >
                            <Input maxLength={20} className="uppercase disabled:text-gray-800 font-normal" />
                        </Form.Item>
                        <Form.Item
                            className=" .order-8 sm:order-none font-semibold px-5 col-span-3 sm:px-0"
                            name="state_2"
                            label={`${t('page.dashboard.state')} 2`}
                            initialValue={userDashboardInformation.state_2}
                        >
                            <Input maxLength={20} className="uppercase disabled:text-gray-800 font-normal" />
                        </Form.Item>
                    </div> */}
                    {/* </Watermark> */}
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            disabled={loading}
                            className="bg-customColor-exor_blue text-white hover:border-customColor-exor_blue hover:text-customColor-exor_blue py-5 ml-3 sm:mx-0 rounded-lg w-[95%] sm:w-1/4"
                        >
                            <FiSave />
                            {t('btn.confirmation.save')}</Button>
                    </Form.Item>
                </Form>
                :
                <DashboardFormSkeleton t={t} />
            }
            {NotifyComponent}
        </LayoutPage >
    )
}