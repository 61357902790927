import { useEffect, useMemo, useRef, useState } from "react"
import usePagination from "../../../hook/usePagination"
import Service from "../../../service/sendRequest"
import useRmaStatus from "../../../hook/useRmaStatus"
import { iRmaPostViewPage } from "../../../util/types"
import { Avatar, Button, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Field, Label, SearchBox, Select, Spinner, TableCellLayout, TableColumnDefinition } from "@fluentui/react-components"
import { Alert, Pagination } from "antd"
import { STATUS_RMA_CENTER_DEFAULT_DRAFT, STATUS_RMA_CENTER_DEFAULT_SHIPPED } from "../../../util/const"
import ManagerView from "../../manager/view/manager-view"
import { useTranslation } from "react-i18next"

export default function RMAPage() {
    const { hashStatus } = useRmaStatus()
    const [optionPagination, setOptionPagination] = useState('')
    const { data, updatePagination, limit, total, skip, loading, update } = usePagination<iRmaPostViewPage>(Service.getRmaAdminManager, 'id', 20, optionPagination)
    const [selectStatus, setSelectStatus] = useState('2')
    const [selectFilter, setSelectFilter] = useState('f_n')
    const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout>()
    const [searchInput, setSearchInput] = useState('')
    const inputRef = useRef('')

    const { t } = useTranslation()


    const [openViewPost, setOpenViewPost] = useState(false)
    const [postIdSelected, setPostIdSelected] = useState(-1)


    const renderOptionStatus = useMemo(() => Object.values(hashStatus)
        .map(({ id, status }) => (
            <option key={2} value={id}>{status}</option>
        )), [hashStatus])
    const onChange = (event: any) => setSearchInput(() => event.target.value || ' ')


    useEffect(() => {
        if (searchInput.length === 0 || inputRef.current === searchInput) return
        if (currentTimeout) clearTimeout(currentTimeout)

        const newTimeout = setTimeout(() => {
            setOptionPagination(() => `&s=${selectStatus}&${selectFilter}=${searchInput}`.replaceAll(' ', ''))
            inputRef.current = searchInput
        }, 1000)

        setCurrentTimeout(newTimeout)
        return () => clearTimeout(newTimeout)
    }, [searchInput])


    const handleSearchStatus = (state: string) => {
        let search = `&${selectFilter}=${searchInput}`.replaceAll(' ', '')
        if (state !== '...' && selectFilter !== 'f_rc')
            search += `&s=${state}`
        setSelectStatus(() => state)
        setOptionPagination(() => search)
    }

    const handleSearchFilter = (filter: string) => {
        setSelectFilter(() => filter)
        console.log('searchInput.length', searchInput.length)
        if (searchInput.length !== 0)
            setOptionPagination(() => `&${filter}=${searchInput}`.replaceAll(' ', ''))
    }


    const columns: TableColumnDefinition<iRmaPostViewPage>[] = useMemo(() => [
        createTableColumn<iRmaPostViewPage>({
            columnId: 'name',
            renderHeaderCell: () => {
                return <span className="font-semibold">Name</span>
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout
                        media={
                            <Avatar
                                // aria-label={item.user?.name}
                                name={item.user?.name}
                            />
                        }
                    >
                        <div className="flex flex-col gap-0">
                            <span className="font-semibold"> {item.user?.name}</span>
                            <span className="hidden xl:block"> {item.user?.email}</span>
                        </div>
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<iRmaPostViewPage>({
            columnId: 'information',
            renderHeaderCell: () => {
                return <span className="font-semibold">Information</span>;
            },
            renderCell: (item) => {
                return item.information
            },
        }),
        createTableColumn<iRmaPostViewPage>({
            columnId: 'status',
            renderHeaderCell: () => {
                return <span className="flex w-full justify-center font-semibold">Status</span>;
            },
            renderCell: (item) => {
                let status = item.status ? hashStatus[`${item.status}`]?.status : item.status
                let typeStatus = 'info' as any
                let className = ' text-blue-900'

                if (status === STATUS_RMA_CENTER_DEFAULT_SHIPPED) {
                    typeStatus = "success"
                    className = ' text-green-900'
                }
                if (status === STATUS_RMA_CENTER_DEFAULT_DRAFT) {
                    typeStatus = "warning"
                    className = ' text-yellow-600'
                }
                return (<div className="flex justify-center w-full">
                    <Alert className={`${className} w-[100px] text-center `} message={status} type={typeStatus} />
                </div>)
            },
        }),
        createTableColumn<iRmaPostViewPage>({
            columnId: 'create_at',
            renderHeaderCell: () => {
                return <span className="font-semibold">Created</span>
            },
            renderCell: (item) => {
                return item.create_at
            },
        }),
        createTableColumn<iRmaPostViewPage>({
            columnId: "actions",
            renderHeaderCell: () => {
                return <span className="font-semibold">Actions</span>
            },
            renderCell: (user) => {
                return (
                    <div className="flex gap-1">
                        <Button onClick={() => { setPostIdSelected(() => user.id); setOpenViewPost(() => true) }} aria-label="Open" > View</Button >
                    </div>
                );
            },
        }),
    ], [data])

    return (
        <>
            <header className="flex w-full gap-2">
                <div className="w-full">
                    <Label htmlFor="search-input-admin-page" weight="semibold">Search</Label>
                    <Field>
                        <SearchBox
                            id="search-input-admin-page"
                            value={searchInput}
                            onChange={onChange}
                            placeholder="Search by name or email or RMA code"
                            appearance="outline"
                        />
                    </Field>
                </div>
                <div className="w-[100px]">
                    <Label htmlFor="search-filter-admin-page" weight="semibold">Filter</Label>
                    <Select id="search-filter-admin-page" onChange={(event) => handleSearchFilter(event.target.value)} >
                        <option value={'f_n'}>Email</option>
                        <option value={'f_rc'}>RMA</option>
                        <option value={'f_e'}>Name</option>
                    </Select>
                </div>
                <div className="w-1/3">
                    <Label htmlFor="search-select-admin-page" weight="semibold">Status</Label>
                    <Select defaultValue={2} onChange={(event) => handleSearchStatus(event.target.value)} id="search-select-admin-page" >
                        <option> ... </option>
                        {renderOptionStatus}
                    </Select>
                </div>
            </header>

            <div className="overflow-x-hidden pb-[50px] ">
                {loading ? <Spinner className="py-[100px] sm:py-[200px]" label="Loading ..." />
                    : <>
                        <DataGrid
                            items={data}
                            className="overflow-x-auto"
                            selectionMode="single"
                            focusMode="composite"
                            style={{ minWidth: "550px" }}

                            columns={columns}
                            sortable
                        >
                            <DataGridHeader>
                                <DataGridRow>
                                    {({ renderHeaderCell }) => (
                                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                    )}
                                </DataGridRow>
                            </DataGridHeader>
                            <DataGridBody<iRmaPostViewPage>>
                                {({ item, rowId }) => (
                                    <DataGridRow<iRmaPostViewPage>
                                        key={rowId}
                                        selectionCell={{ radioIndicator: { "aria-label": "Select row" } }}
                                    >
                                        {({ renderCell }) => (
                                            <DataGridCell>{renderCell(item)}</DataGridCell>
                                        )}
                                    </DataGridRow>
                                )}
                            </DataGridBody>
                        </DataGrid>
                        <Pagination
                            showSizeChanger={false}
                            onChange={(current) => updatePagination(current)}
                            className="my-5 float-end"
                            defaultPageSize={limit}
                            defaultCurrent={skip}
                            total={total}
                            pageSize={limit}
                        />
                    </>}
            </div>
            < ManagerView
                hashStatus={hashStatus}
                t={t}
                open={openViewPost}
                onClose={() => { setOpenViewPost(() => false); setPostIdSelected(-1); }}
                idPostSelected={postIdSelected}
            />
        </>
    )
}