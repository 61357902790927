import { useReducer } from "react"

type iActionsTypes = 'INIT' | 'ADD' | 'DELETE'

type iReducerAction = {
    type: iActionsTypes,
    payload: any
}

interface iAttachmentsView {
    id: number
    link: string
    name: string
}

type iReducerState = {
    files: iAttachmentsView[]
}

const INITIAL_STATE: iReducerState = {
    files: []
}

function reducer(state: iReducerState, action: iReducerAction): iReducerState {
    try {

        if (action.type === 'ADD')
            return { ...state, files: [...state.files, ...action.payload] }

        if (action.type === 'INIT')
            return { ...state, files: action.payload }

        if (action.type === 'DELETE')
            return { ...state, files: state.files.filter(({ id }) => id !== action.payload) }

        return state
    } catch (error) {
        console.log('error', error);
        return state
    }
}


export default function useReview() {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    return { state, dispatch }
}