import { useMemo } from "react"
import { useLocation } from "react-router-dom"



export default function useValidationPath(path: string, deep: number = 1) {
    const { pathname } = useLocation()

    const isPathValidation = useMemo(() => {
        return pathname.split('/')[deep] === path
    }, [pathname])

    // const isTicketPath = useMemo(() => pathname.split('/')[1] == 'ticket', [pathname])

    return { pathname, isPathValidation }
}