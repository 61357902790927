import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import usePagination from "../../hook/usePagination";
import LayoutPage from "../../component/ui/layout-page-component";
import useRmaStatus from "../../hook/useRmaStatus";
import ManagerViewEdit from "../manager/view/manager-view";
import Service from "../../service/sendRequest";
import { Pagination, Alert, Button, Empty, Table, Select, Input, Form, DatePicker } from "antd";
import useSelectLimitPagination from "../../component/ui/select-limit-pagination";
import type { TableColumnsType } from 'antd';
import { iRmaPostViewPage } from "../../util/types";
import { STATUS_RMA_CENTER_DEFAULT_DRAFT, STATUS_RMA_CENTER_DEFAULT_SHIPPED } from "../../util/const";

import { FaUserCircle } from "react-icons/fa";
import { TbMail } from "react-icons/tb";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Avatar, TableCellLayout, Tooltip } from "@fluentui/react-components";
import { IoEyeSharp } from "react-icons/io5";


// import ButtonFilter from "../../component/ui/button-filter";


export default function CorporationPage() {
  const { t } = useTranslation()


  const { hashStatus } = useRmaStatus()
  const [typeSearchFilter, setTypeSearchFilter] = useState('f_e')
  const [optionsPropsPagination, setOptionsPropsPagination] = useState('')
  const { limit: limitRMA, ComponentPagination } = useSelectLimitPagination(t)
  const { data: rmaPost, updatePagination, skip, limit, total, loading } = usePagination<iRmaPostViewPage>(Service.getCorporationsPosts, 'id', limitRMA, optionsPropsPagination)
  const [postIdSelected, setPostIdSelected] = useState(-1)
  const [openViewPost, setOpenViewPost] = useState(false)

  const renderStateSelect = useMemo(() => {
    const stateList = []
    stateList.push({ value: -1, label: 'ALL' })
    for (let key in hashStatus) {
      stateList.push({ value: hashStatus[key].id, label: hashStatus[key].status })
    }
    return stateList
  }, [hashStatus])

  const CorporationColumns: TableColumnsType<iRmaPostViewPage> = useMemo(() => [
    {
      // title: t('page.manager.information'),
      title: 'User',
      dataIndex: "user",
      render: (_, record) => {
        return (
          <TableCellLayout
            media={
              <Avatar name={record.user?.name} />
            }
          >
            <Tooltip positioning={"below"} content={record.user?.email + ''} relationship="label">
              <span>{record.user?.name}</span>
            </Tooltip>
          </TableCellLayout>
        )
      },
    },
    {
      title: t('page.manager.information'),
      dataIndex: "information",
      width: '40%',

    },
    {
      title: t("page.manager.description"),
      dataIndex: "full_description",
      render: ((_, record) => record.full_description.length >= 120 ? `${record.full_description.slice(0, 120)}...` : record.full_description),
      width: '30%',
    },
    {
      title: t("page.manager.status"),
      key: "status",
      render: (_, record) => {
        let status = record?.status ? hashStatus[`${record?.status}`]?.status : '...'
        let typeStatus = 'info' as any
        let className = 'text-customColor-exor_blue'

        if (status === STATUS_RMA_CENTER_DEFAULT_SHIPPED) {
          typeStatus = "success"
          className = 'text-green-900'
        }
        if (status === STATUS_RMA_CENTER_DEFAULT_DRAFT) {
          typeStatus = "warning"
          className = 'text-yellow-800'
        }
        return (<Alert className={`${className} text-center w-auto`} message={status} type={typeStatus} />)
      },
      filters: renderStateSelect.map(({ label, value }) => { return { text: label, value } }),
      onFilter: (value, record) => value === record.status || value === -1,
      // `${record.status}`.startsWith(value as string),
      filterSearch: true,
      width: '5%',

    },
    {
      title: t("page.manager.create_at"),
      dataIndex: "create_at",
    },
    {
      title: t('message.action'),
      key: 'action',
      render: (_, record) => (
        <div className="flex items-center gap">
          {/* <Tooltip openClassName="hidden md:block" placement="bottomRight" title={t('message.tooltip.view')} arrow={true}> */}
          <Button icon={<IoEyeSharp />} onClick={() => { setPostIdSelected(() => record.id); setOpenViewPost(() => true) }} className="text-customColor-exor_blue hover:underline font-semibold" type="dashed">
            {t('message.action.view')}
          </Button>
          {/* </Tooltip> */}
        </div>
      ),
      fixed: 'right',
    },
  ]
    , [t, hashStatus])


  const onSearchFilter = async (form: any) => {
    const { filter, date_picker, status } = form
    const options = []
    if (filter)
      options.push(`${typeSearchFilter}=${filter}`)
    if (status)
      options.push(`s=${status}`)
    if (date_picker) {
      const start_date = date_picker[0]
      const end_date = date_picker[1]
      options.push(`sd=${start_date.$D}/${start_date.$M + 1}/${start_date.$y}&ed=${end_date.$D}/${end_date.$M + 1}/${end_date.$y}`)
    }
    const opt = options.join('&').replaceAll(' ', '')
    setOptionsPropsPagination(() => `&${opt}`)
  }

  return (
    <LayoutPage
      title={t("page.title.corporation")}
      title_layout={t("nav.path.corporation")}
      childrenHeader={ComponentPagination}
      className="md:container pt-5 md:px-2 mx-auto"
    >
      <hr />
      <header className="max-w-7xl">
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onSearchFilter}
          className="flex flex-col sm:flex-row justify-normal items-center px-4 sm:px-0"
        >
          <div className="font-semibold w-full grid gap-1 grid-cols-4 justify-start items-center pt-5" >

            <div className="flex items-center gap-2 col-span-4 md:col-span-3 w-full">
              <Form.Item name="filter" label={t('page.corporations.search')} className="w-full md:w-[80%]" >
                <Input className="font-normal w-full" prefix={
                  typeSearchFilter === 'f_rc' ?
                    // <GrDocumentConfig />
                    <AiOutlineFileSearch />
                    :
                    typeSearchFilter === 'f_n' ?
                      <FaUserCircle />
                      :
                      typeSearchFilter === 'f_e' ?
                        <TbMail /> : <></>
                } size="middle"
                />
              </Form.Item>
              <Select size="middle" className="w-[30%] md:w-[20%] h-9 pt-1" defaultValue={'f_e'} placeholder="Type Search" onChange={(value: string) => setTypeSearchFilter(value)}>
                <Select.Option value="f_e">Email</Select.Option>
                <Select.Option value="f_rc">RMA Code</Select.Option>
                <Select.Option value="f_n">Name</Select.Option>
              </Select>
            </div>
            <Form.Item className="font-semibold col-span-4 sm:col-span-1" label={t('page.corporations.status')} name="status" rules={[]} rootClassName="">
              <Select
                // placeholder="Select by Status"
                defaultValue={'ALL'}
                size="middle"
                rootClassName="font-normal placeholder:text-gray-700"
                style={{ width: '100%' }}
                options={renderStateSelect}
              />
            </Form.Item>
            <Form.Item className="font-semibold col-span-3 sm:col-span-2 lg:col-span-3" label={t('page.corporations.filter_by')} name="date_picker">
              {/* <DatePicker  /> */}
              <DatePicker.RangePicker format={"DD/MM/YYYY"} size="middle" picker="date" />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="font-semibold rounded-md">{t('page.corporations.search')}</Button>
          </div>
        </Form>
      </header>
      {rmaPost.length !== 0 || loading ?
        <>
          <Table
            loading={loading}
            columns={CorporationColumns}
            dataSource={rmaPost}
            bordered
            pagination={false}
            className="overflow-y-hidden overflow-x-auto shadow-none border-gray-200 sm:border-y-2 rounded-md"
          />
          <Pagination
            showSizeChanger={false}
            onChange={(current) => updatePagination(current)}
            className="my-5 float-end"
            defaultPageSize={limit}
            defaultCurrent={skip}
            current={skip}
            total={total}
            pageSize={limit}
            // showTotal={(total: number) => `Total ${total} RMA Requests` as any}
            showTotal={(total: number) => t('page.corporations.total_page').replace('</>', total + '')}
          />
        </>
        :
        <Empty description={t('page.manager.not_found')} />
      }
      <ManagerViewEdit
        hashStatus={hashStatus}
        t={t}
        open={openViewPost}
        onClose={() => { setOpenViewPost(() => false); setPostIdSelected(-1); }}
        idPostSelected={postIdSelected}
      />
    </LayoutPage>
  )
}