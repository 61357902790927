import { CSSProperties, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { useOnMountUnsafe } from "../hook/useOnMountUnsafe";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const content = <div style={{
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
} as CSSProperties} />;

export default function LayoutAuthProvider({ children }: { children: React.ReactNode }) {
    const { keycloak: { authenticated } } = useKeycloak()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    useOnMountUnsafe(() => {
        if (!authenticated) {
            navigate('/')
            return
        }
    })
    useEffect(() => {
        if (pathname === '/auth/' || pathname === '/auth') {
            navigate('/')
            return
        }
    }, [pathname])

    if (authenticated)
        return (
            <div className="flex overflow-x-auto h-full">
                <div className="w-full animate-fade-in-down animate-duration-faster">
                    {children}
                </div>
            </div>
        )

    return (
        <div className="flex justify-center h-[300px] md:h-[500px]">
            <Spin tip={t("toast.message.loading")} indicator={<LoadingOutlined spin />} size="large" >{content}</Spin>
        </div>
    )
}