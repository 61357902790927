import { useState } from "react";
import { Form, Select } from "antd";
import { KEY_DEFAULT_CONFIG_PAGINATION_LIMIT } from "../../util/const";


export default function useSelectLimitPagination(t: Function) {
    const [limit, setLimit] = useState(localStorage.getItem(KEY_DEFAULT_CONFIG_PAGINATION_LIMIT) ? parseInt(localStorage.getItem(KEY_DEFAULT_CONFIG_PAGINATION_LIMIT) + '') : 10)

    return {
        limit,
        ComponentPagination: (
            (
                <div className="px-2 sm:px-0 flex items-center gap-2 text-sm">
                    <Form.Item label={t('page.dashboard.limit_page')}>
                        <Select defaultValue={limit} onChange={(value: number) => { setLimit(() => value); localStorage.setItem(KEY_DEFAULT_CONFIG_PAGINATION_LIMIT, value + '') }}>
                            <Select.Option value={10}>10</Select.Option>
                            <Select.Option value={20}>20</Select.Option>
                            <Select.Option value={30}>30</Select.Option>
                            <Select.Option value={40}>40</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
            )
        )
    }
}
