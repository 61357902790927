
export default function ManagerViewSkeleton({ t }: { t: Function }) {
    return (
        <div className="animate-pulse grid grid-cols-4 items-center gap-2 ">

            <span className='col-span-3 my-4 text-lg font-bold  text-gray-900 lg:mb-6 lg:text-2xl'>{t('page.manager.view.information')}</span>
            <span className='col-span-1 w-full ' >
                <div className="float-right border bg-slate-300 w-full h-5 rounded-md my-3" />
            </span>

            <div className='col-span-2 sm:col-span-3'>
                <div className="border bg-slate-300 w-1/4 h-5 rounded-md mb-3" />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className='col-span-2 sm:col-span-1'>
                <div className="border bg-slate-300 w-1/4 h-5 rounded-md mb-3" />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className='mt-5 col-span-4 sm:col-span-3'>
                <div className="border bg-slate-300 w-1/6 h-5 rounded-md mb-3" />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className='mt-5 col-span-4 sm:col-span-1'>
                <div className="border bg-slate-300 w-1/4 h-5 rounded-md mb-3" />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className='mt-5 col-span-4 sm:col-span-3'>
                <div className="border bg-slate-300 w-1/4 h-5 rounded-md mb-3" />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className='mt-5 col-span-4 sm:col-span-1'>
                <div className="border bg-slate-300 w-1/2 h-5 rounded-md mb-3" />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className='mt-5 col-span-4 sm:col-span-2'>
                <div className="border bg-slate-300 w-1/2 h-5 rounded-md mb-3" />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className='mt-5 col-span-4 sm:col-span-2'>
                <div className="border bg-slate-300 w-1/2 h-5 rounded-md mb-3" />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>

            <div className='mt-6 col-span-4 h-[250px]'>
                <div className="border bg-slate-300 w-1/6 h-5 rounded-md mb-3" />
                <div className="border bg-slate-300 h-[220px] rounded-md" />
            </div>

            <div className="col-span-4 ">
                <h3 className='font-bold text-lg' >Attachments</h3>
                <div className=" w-full py-2 gap-2 h-[120px] max-h-[120px] bg-slate-300 overflow-y-auto rounded-md mt-2"></div>
            </div>


            <h3 className="col-span-4 my-4 text-lg font-bold text-gray-900 lg:mb-6 lg:text-2xl">{t('page.manager.view.destination')}</h3>

            <div className="col-span-4 mt-1">
                <div className="border bg-slate-300 w-1/2 sm:w-1/3 lg:w-1/6 h-4 rounded-md mb-2 " />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className="col-span-4 mt-1">
                <div className="border bg-slate-300 w-1/2 sm:w-1/3 lg:w-1/6 h-4 rounded-md mb-2 " />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className="col-span-4 mt-1">
                <div className="border bg-slate-300 w-1/2 sm:w-1/3 lg:w-1/6 h-4 rounded-md mb-2 " />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className="col-span-4 mt-1">
                <div className="border bg-slate-300 w-1/2 sm:w-1/3 lg:w-1/6 h-4 rounded-md mb-2 " />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className="col-span-4 mt-1">
                <div className="border bg-slate-300 w-1/2 sm:w-1/3 lg:w-1/6 h-4 rounded-md mb-2 " />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className="col-span-4 mt-1">
                <div className="border bg-slate-300 w-1/2 sm:w-1/3 lg:w-1/6 h-4 rounded-md mb-2 " />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className="col-span-4 mt-1">
                <div className="border bg-slate-300 w-1/2 sm:w-1/3 lg:w-1/6 h-4 rounded-md mb-2 " />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>
            <div className="col-span-4 mt-1">
                <div className="border bg-slate-300 w-1/2 sm:w-1/3 lg:w-1/6 h-4 rounded-md mb-2 " />
                <div className="border bg-slate-300 w-full h-8 rounded-md" />
            </div>

        </div >
    )
}