import { useMemo, useState } from "react";
import useUpload from "./upload.reducer";
import { Image } from "antd";
import { LuPaperclip } from "react-icons/lu";
import { IoCloudUpload, IoTrashOutline } from "react-icons/io5";
import { iAttachments } from "../../../../util/types";
import { useTranslation } from "react-i18next";


const getBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });


export default function useComponentUpload({ attachments, id, }: { attachments: iAttachments[], id: number, t: (key: string) => string }) {
    const { upload: { files }, dispatch } = useUpload()
    const [previewImage, setPreviewImage] = useState('')
    const [previewOpen, setPreviewOpen] = useState(false)
    const { i18n: { language }, t } = useTranslation()


    const handleOnChangeInput = (event: any) => {
        const selectedImages = event.target.files;
        const images: File[] = []
        for (let i = 0; i < selectedImages.length; i++) {
            const image = selectedImages[i] as File;
            images.push(image)
        }
        dispatch({ type: 'ADD_FILE', payload: images })
    }

    const renderButtonUpload = useMemo(() => {
        return (
            <>
                <label form="upload-file" className=" cursor-pointer flex justify-center items-center gap-2 p-1 border-gray-200 hover:border-customColor-exor_blue active:border-customColor-exor_blue border-2 rounded-t-md">
                    <IoCloudUpload className="text-customColor-exor_blue" size={30} />
                    <p className="ant-upload-text">{t('page.manager.attachment')}</p>
                    <input
                        onChange={handleOnChangeInput}
                        id="upload-file" type="file" multiple style={{ display: 'none' }} />
                </label>
                <div className="flex flex-col w-full py-2 gap-2 h-[120px] max-h-[120px] bg-slate-50 overflow-y-auto  rounded-b-md">
                    {files.map(({ name }, i) => {
                        return (
                            <div className="flex items-center justify-between font-normal hover:bg-slate-100 px-2 hover:opacity-50" key={i}>
                                <div className="flex items-center  gap-1 hover:underline cursor-pointer" onClick={() => handlePreview(i)}>
                                    <LuPaperclip />
                                    <span>{name}</span>
                                </div>
                                <IoTrashOutline onClick={() => dispatch({ type: 'DELETE_FILE', payload: i })} className="cursor-pointer" />
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }, [files, language])


    const renderViewer = useMemo(() => {
        return <>
            {previewImage && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </>
    }, [previewOpen])


    const handlePreview = async (index: number) => {
        const selectedFile = files[index]
        if (!['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'image/heif'].includes(selectedFile.type)) return
        if (!selectedFile) return
        const preview = await getBase64(selectedFile)
        setPreviewImage(preview)
        setPreviewOpen(true)
    }

    return { renderViewer, renderButtonUpload, files }
}
// const handleSubmit = async () => {
//     try {
//         const formData = new FormData()
//         formData.append('length', files.length + '')
//         for (let i = 0; i < files.length; i++) {
//             formData.append(`file_${i}`, files[i]);
//         }
//         const response = Service.postUpload(formData)
//         console.log('response: ', response)
//     } catch (error) {
//         console.log('error: ', error)
//     }
// }
// const testRefInput = useRef<any>('')
// const handleTest = async () => {
//     console.log('testRefInput.current: ', testRefInput.current.value)
//     try {
//         const response = Service.deleteFile(testRefInput.current.value)
//     } catch (error) {
//         console.log('error: ', error)
//     }
// }
// return (
//     <div className="flex flex-col items-center justify-center w-full sm:w-1/2">
//         {/* <label form="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 .dark:hover:bg-gray-800 .dark:bg-gray-700 hover:bg-gray-100 .dark:border-gray-600 .dark:hover:border-gray-500 .dark:hover:bg-gray-600">
//             <div className="flex flex-col items-center justify-center pt-5 pb-6">
//                 <svg className="w-8 h-8 mb-4 text-gray-500 .dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
//                     <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
//                 </svg>
//                 <p className="mb-2 text-sm text-gray-500 .dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
//                 <p className="text-xs text-gray-500 .dark:text-gray-400">SVG, PNG, JPG or GIF </p>
//             </div>
//             <input onChange={handleOnChangeInput} id="dropzone-file" type="file" className="hidden" multiple />
//         </label> */}
//         {renderListImages}
//         {/* <button onClick={() => handleSubmit()}>Submit</button> */}
//         {/* <div className="flex flex-col justify-center items-center bg-blue-300 h-[200px] p-5 gap-5">
//             <input ref={testRefInput} />
//             <button onClick={() => handleTest()} className="bg-blue-500 p-4 text-white">DELETE BY ID</button>
//         </div> */}
//     </div>
// )