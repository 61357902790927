import { useMemo } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ToastProvider } from "../context/toast.context";
import { HelmetProvider } from 'react-helmet-async';
import { keycloak } from "../service/keycloak.service";
import LoadingComponent from "./ui/loading-component";
import { ConfigProvider, ThemeConfig } from "antd";
import { useTranslation } from "react-i18next";
import { LocalConfigProvider } from "../context/local-config-user.context";
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

// lang DatePicker
import locale_de from 'antd/locale/de_DE'
import locale_en from 'antd/locale/en_US'
import locale_it from 'antd/locale/it_IT'
import locale_es from 'antd/locale/es_ES'

const INIT_OPTIONS = { onLoad: 'check-sso' }  // const INIT_OPTIONS = { onLoad: 'login-required' }

const themeProvider: ThemeConfig = {
    token: {
        colorPrimary: '#0046ad',
        colorPrimaryHover:'#003478',
    },
    components: {
        Button: {
            colorPrimary: '#0046ad',
        },
        Input: {
            colorPrimary: '#0046ad',
        }
    },
}


export default function ProviderComponent({ children }: { children: React.ReactNode }) {
    const { i18n: { language } } = useTranslation()
    const configLangPicker = useMemo(() => {
        if (language === "en")
            return locale_en
        if (language === "it")
            return locale_it
        if (language === "de")
            return locale_de
        if (language === "es")
            return locale_es
        return locale_en
    }, [language])

    return (
        <ReactKeycloakProvider authClient={keycloak} initOptions={INIT_OPTIONS} LoadingComponent={<LoadingComponent />} >
            <ConfigProvider theme={themeProvider} locale={configLangPicker}>
                <FluentProvider theme={webLightTheme}>
                    <HelmetProvider>
                        <ToastProvider>
                            <LocalConfigProvider>
                                {children}
                            </LocalConfigProvider>
                        </ToastProvider>
                    </HelmetProvider>
                </FluentProvider>
            </ConfigProvider>
        </ReactKeycloakProvider>
    )
}
