import { useRef, useState } from 'react';
import type { StatisticProps } from 'antd';
import { useToast } from '../../context/toast.context';
import useOnchange from '../../hook/useChange';
import LayoutPage from '../../component/ui/layout-page-component';
import { useTranslation } from 'react-i18next';
import Service from '../../service/sendRequest';
import { Typography, Input, Button, Statistic, Alert } from 'antd';
import { REACT_APP_RECAPTCHA_SITE_KEY } from '../../util/const';
import ReCAPTCHA from 'react-google-recaptcha';


const { Title, Paragraph } = Typography;

const formatter: StatisticProps['formatter'] = (value) => (
    value // <CountUp end={value as number} separator="," />
)
const INITIAL_STATE = { fixBuild: "", status: "" }

export default function TicketPage() {

    const { t, i18n: { language } } = useTranslation()
    const reCaptcha = useRef<any>()

    const [ticketInformation, setTicketInformation] = useState(INITIAL_STATE)
    const [isLoading, setIsLoading] = useState(false)

    const [validationCaptcha, setValidationCaptcha] = useState(true)

    const [validationInputMessage, setValidationInputMessage] = useState({ message: "", status: "" })
    const [state, , onChange] = useOnchange({ search_ticket_rma: "" })
    const { loading, success, error } = useToast();
    const refInput = useRef<string | null>(null)
    const intervalId = useRef<NodeJS.Timer>()


    const handleValidateInput = (event: any) => {
        if (intervalId.current)
            clearInterval(intervalId.current)
        const inputText = event.target.value as string

        const regex = /^(?:[A-Z0-9]{1,10}-\d{0,14})$/

        if (inputText.length === 0) return setValidationInputMessage(() => { return { message: "", status: "" } })

        if (!regex.test(inputText)) return setValidationInputMessage(() => { return { message: t('page.ticket.message.error'), status: "error" } })

        setValidationInputMessage(() => { return { message: t("page.ticket.message.success"), status: "success" } })

        intervalId.current = setInterval(() => {
            setValidationInputMessage(() => { return { message: "", status: "" } })
        }, 2000);
    }

    const handleReCAPTCHA = (token: any) => {
        if (token) setValidationCaptcha(() => false)
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        const captcha_value = reCaptcha.current.getValue();
        if (captcha_value === '' || refInput.current === state.search_ticket_rma)
            return

        setIsLoading(() => true)
        setTicketInformation(() => INITIAL_STATE)
        const key = 'handleSubmit';
        try {

            if ((state.search_ticket_rma as string).length === 0) {
                error({ key, content: t('page.ticket.message.error'), });
                throw Error(t("page.ticket.message.error"))
            }

            const ticket_code = state.search_ticket_rma as string
            setValidationCaptcha(() => true)

            // to do query
            loading({ key })
            const response = await Service.getTicketByCodeTicket({ ticket_code: ticket_code.replaceAll(' ', ''), captcha_value })
            await reCaptcha.current.reset()
            if (response?.error) throw Error(response.errorMessages)

            if (response?.status !== "ExceptionNotFound") success({ key })

            setTicketInformation(() => response)
            setIsLoading(() => false)
            refInput.current = state.search_ticket_rma

        } catch (err: any) {
            setIsLoading(() => false)
            console.log(err)
            setTimeout(() => {
                error({
                    key,
                    content: t('page.manager.message.error'),
                    duration: 5000,
                });
            }, 1000);
        }
    }
    return (
        <LayoutPage title='Exor International - Ticket' disableDefaultClassName className='mt-[82px] grid gap-5 grid-cols-1 md:grid-cols-2 md:px-20 md:pb-72 px-5 py-2 max-w-7xl mb-24'>
            <Title level={1} className='col-span-2'>{t("page.ticket.tile")}</Title>
            <div className='col-span-2 sm:col-span-1 pr-5 max-w-2xl'>
                <Typography>
                    <Paragraph>{t("page.ticket.description")}</Paragraph>
                </Typography>
                <form onSubmit={handleSubmit} className='flex gap-1 w-full '>
                    <Input
                        placeholder={t("page.ticket.word.input_message")}
                        allowClear
                        name='search_ticket_rma'
                        size="large"
                        value={state.search_ticket_rma}
                        onChange={(event: any) => { onChange(event); handleValidateInput(event); }}
                        status={validationInputMessage.status as any}
                    />
                    <Button
                        htmlType='submit'
                        disabled={((state.search_ticket_rma as string).length === 0) || isLoading || validationCaptcha}
                        className='bg-blue-600 h-10' type='primary'>
                        {t("page.ticket.word.search")}
                    </Button>
                </form>
                {validationInputMessage.message.length !== 0 &&
                    <Alert
                        className='mt-2'
                        message={validationInputMessage.message}
                        type={validationInputMessage.status as any}
                    />
                }
                {ticketInformation.status === "ExceptionNotFound" &&
                    <Alert className='mt-2' message={t('page.ticket.not_found')} type="info" showIcon />
                }
                <ReCAPTCHA
                    ref={reCaptcha}
                    lang={language}
                    className='pt-5'
                    sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                    size="normal"
                    onChange={(token: any) => handleReCAPTCHA(token)}
                    onExpired={() => setValidationCaptcha(() => true)}
                />
            </div>
            <div className='pt-10 sm:pt-0'>
                {ticketInformation.fixBuild &&
                    <>
                        <h3 className='text-2xl font-medium text-gray-700 '>Fix Build/s</h3>
                        <Statistic
                            // title="Fix Build/s"
                            value={ticketInformation.fixBuild}
                            formatter={formatter}
                        />
                        <div className=''>
                            <h3 className='text-2xl font-medium text-gray-700 mt-5 mb-2'>{t("page.ticket.word.status")}</h3>
                            {
                                ticketInformation.status !== "ExceptionNotFound" ?
                                    ticketInformation.status
                                    :
                                    '-'
                            }
                        </div>
                    </>
                }
            </div>
        </LayoutPage>
    )
}