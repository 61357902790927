import { useEffect, useMemo, useReducer, useRef, useState } from "react"
import { reducer, INITIAL_STATE_ADDRESS } from "./address.reducer"
import Service from "../../../service/sendRequest"
import { useOnMountUnsafe } from "../../../hook/useOnMountUnsafe"
import { useModalConfirm } from "../../../context/toast.context"

import { Button, Form, Input, Table, TableColumnsType } from "antd"
import { iDestination, iDirections } from "../../../util/types"

import { MdVerified } from "react-icons/md"
import { GoUnverified } from "react-icons/go"
import { GrAdd, GrConfigure, GrSelect } from "react-icons/gr"
import { IoReturnUpBackSharp } from "react-icons/io5"

type props = {
    t: (key: string) => string
    setDestinationParent: (destination: iDirections) => void
    defaultDestination: iDirections
    className?: string
    verified?: boolean
    type: 'CREATE' | 'EDIT'
}

export default function Address({
    t,
    setDestinationParent,
    defaultDestination,
    className = '',
    type,
    verified = false
}: props) {
    const [address, dispatch] = useReducer(reducer, INITIAL_STATE_ADDRESS)
    const [loading, setLoading] = useState(false)
    const initialized_destination = useRef(false)

    const validationConfirm = useRef(false)
    const { funcModalConfig: modal } = useModalConfirm()

    useOnMountUnsafe(() => {
        const getListDestination = async () => {
            try {
                setLoading(() => true)
                const response = await Service.getDirectionsByIdUser()
                if (response?.data)
                    dispatch({ type: "INITIAL_LIST_DESTINATION", payload: response.data })
            } catch (error) {
                console.log('error: ', error)
            } finally {
                setLoading(() => false)
            }
        }
        getListDestination()
    })

    useEffect(() => {
        if (!initialized_destination.current && defaultDestination.id !== -1) {
            initialized_destination.current = true
            dispatch({ type: "INITIAL_DESTINATION", payload: { ...defaultDestination, verified_destination: verified }, })
            setDestinationParent({ ...defaultDestination, verified_destination: verified })
        }
    }, [defaultDestination.id])

    useEffect(() => {
        if (address.list_destination?.length === 0)
            return dispatch({ type: 'CREATE_DESTINATION' })

        if (!address.list_destination && type === 'CREATE')
            return dispatch({ type: 'SHOW_LIST' })
    }, [type, address.list_destination])

    useEffect(() => {
        dispatch({ type: 'SET_VALIDATE_DESTINATION', payload: verified })
    }, [verified])

    useEffect(() => {
        if (address.verified) validationConfirm.current = false
    }, [address.verified])

    const onChange = async (event: any) => {
        if (await validateConfirmEdit()) return
        const { name, value } = event.target
        dispatch({ type: "EDIT_DESTINATION", payload: { name, value } })
        setDestinationParent({ ...address.destination, [name]: value })
    }

    const validateConfirmEdit = async () => {
        if (!validationConfirm.current && address.verified) {
            dispatch({ type: 'EDIT_VERIFIED_DESTINATION' })
            const responseConfirm: boolean = await modal(t('page.manager.confirmation.destination'), '', t('btn.confirmation.accept'), t('btn.confirmation.cancel'))
            if (!responseConfirm) return true
            validationConfirm.current = true
            return true
        }
        return false
    }

    const columns: TableColumnsType<iDestination> = useMemo(() => [
        {
            title: t('page.corporations.name_company'),
            dataIndex: "company_name",
            key: "company_name",
            // width: '40%',
        },
        {
            title: t('page.corporations.address'),
            key: "address",
            render: (_, record) => (<div>
                {record.address_line_1} {record.address_line_2} {record.address_line_3}, {record.city} {record.province}, {record.zip_code}, {record.country}
            </div>)
        },
        {
            title: t('message.action'),
            key: "action",
            render: (_, destination) => (
                <div className="flex items-center gap bl">
                    <Button
                        onClick={() => {
                            dispatch({ type: "SELECT_DESTINATION", payload: { ...destination, verified_destination: true } })
                            setDestinationParent({ ...destination, verified_destination: true })
                        }}
                        className="text-customColor-exor_blue hover:underline font-semibold" type="text">
                        <GrSelect />
                        {t('message.action.select')}
                    </Button>
                </div >
            ),
        },
    ]
        , [t])


    return (
        <div className={`${className} px-2`}>
            <div className={` ${loading && 'animate-pulse'} my-4 text-lg font-bold text-gray-900 lg:mb-6 lg:text-2xl flex justify-between items-center `}>
                <h3>{t('page.manager.view.destination')}</h3>
                {!address.show_list &&
                    <>
                        {address.verified ?
                            <div className='text-base text-customColor-exor_blue flex justify-center items-center'>
                                <MdVerified />
                                <span>{t('page.manager.destination.verified')}</span>
                            </div>
                            :
                            <div className='text-base text-customColor-exor_grey flex gap-1 justify-center items-center'>
                                <GoUnverified />
                                <span>{t('page.manager.destination.not_verified')}</span>
                            </div>
                        }
                    </>
                }
            </div>
            {
                !address.show_list ?
                    <div className={` ${loading && 'animate-pulse'} grid grid-cols-2 gap-2`}>
                        <Form.Item
                            className="col-span-2 sm:col-span-1 sm:order-1 font-semibold"
                            label={t('page.corporations.name_company')}
                            required
                        >
                            <Input required={!address.verified}
                                autoComplete="off"
                                className="disabled:text-gray-700 font-normal"
                                name="company_name"
                                onChange={onChange}
                                value={address.destination.company_name}
                                maxLength={100}
                            />
                        </Form.Item>
                        <Form.Item
                            className="col-span-2 sm:col-span-1 sm:order-3 font-semibold"
                            label={t('page.manager.view.destination.zip_code')}
                            required
                        >
                            <Input required={!address.verified}
                                autoComplete="off"
                                className="disabled:text-gray-700 font-normal"
                                name="zip_code"
                                onChange={onChange}
                                value={address.destination.zip_code}
                                maxLength={10}
                            />
                        </Form.Item>
                        <Form.Item
                            className="col-span-2 sm:col-span-1 sm:order-5 font-semibold"
                            label={t('page.manager.view.destination.city')}
                            required
                        >
                            <Input required={!address.verified}
                                autoComplete="off"
                                className="disabled:text-gray-700 font-normal"
                                name="city"
                                onChange={onChange}
                                value={address.destination.city}
                                maxLength={100}
                            />
                        </Form.Item>
                        <Form.Item
                            className="col-span-2 sm:col-span-1 sm:order-7 font-semibold"
                            label={t('page.manager.view.destination.province')}
                            required
                        >
                            <Input required={!address.verified}
                                autoComplete="off"
                                className="disabled:text-gray-700 font-normal"
                                name="province"
                                onChange={onChange}
                                value={address.destination.province}
                                maxLength={50}
                            />
                        </Form.Item>
                        <Form.Item
                            className="col-span-2 sm:col-span-1 sm:order-2 font-semibold"
                            label={t('page.manager.view.destination.address_1')}
                            required
                        >
                            <Input 
                                required={!address.verified}
                                autoComplete="off"
                                className="disabled:text-gray-700 font-normal"
                                name="address_line_1"
                                onChange={onChange}
                                value={address.destination.address_line_1}
                                maxLength={150}
                            />
                        </Form.Item>
                        <Form.Item
                            className="col-span-2 sm:col-span-1 sm:order-4 font-semibold"
                            label={t('page.manager.view.destination.address_2')}
                            // required
                        >
                            <Input 
                                // required={!address.verified}
                                autoComplete="off"
                                className="disabled:text-gray-700 font-normal"
                                name="address_line_2"
                                onChange={onChange}
                                value={address.destination.address_line_2}
                                maxLength={150}
                            />
                        </Form.Item>
                        <Form.Item
                            className="col-span-2 sm:col-span-1 sm:order-6 font-semibold"
                            label={t('page.manager.view.destination.address_3')}
                            // required
                        >
                            <Input 
                                // required={!address.verified}
                                autoComplete="off"
                                className="disabled:text-gray-700 font-normal"
                                name="address_line_3"
                                onChange={onChange}
                                value={address.destination.address_line_3}
                                maxLength={150}
                            />
                        </Form.Item>
                        <Form.Item
                            className="col-span-2 sm:col-span-1 sm:order-8 font-semibold"
                            label={t('page.manager.view.destination.country')}
                            required
                        >
                            <Input required={!address.verified}
                                autoComplete="off"
                                className="disabled:text-gray-700 font-normal"
                                name="country"
                                onChange={onChange}
                                value={address.destination.country}
                                maxLength={100}
                            />
                        </Form.Item>
                    </div>
                    :
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={address.list_destination ?
                            address.list_destination as iDestination[]
                            : []}
                        className="overflow-x-auto shadow-none border-gray-200 sm:border-y-2 rounded-md"
                    />
            }
            <div className="grid gap-2 grid-cols-2 md:grid-cols-3 pt-2">
                {address.show_list &&
                    <>
                        <Button
                            className={` w-full col-span-2 md:col-span-1`}
                            icon={<IoReturnUpBackSharp />}
                            onClick={() => dispatch({ type: 'CLOSE_SHOW_LIST' })} type="primary" >
                            {t('page.manager.create.back')}
                        </Button>
                        <Button
                            icon={<GrAdd />}
                            className={` w-full col-span-2 md:col-span-1`}
                            onClick={() => dispatch({ type: 'CREATE_DESTINATION' })} type="primary" >
                            {t('page.manager.btn.create_destination')}
                        </Button>
                    </>
                }
                <Button
                    disabled={address.list_destination?.length === 0}
                    icon={<GrConfigure />}
                    onClick={() => dispatch({ type: 'SHOW_LIST' })}
                    type="primary"
                    className="disabled:text-gray-500 w-full col-span-2 md:col-span-1"
                >
                    {t('btn.other_destination')}
                </Button>
            </div>
        </div>
    )
}