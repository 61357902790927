import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Service from "./service/sendRequest";
import { useOnMountUnsafe } from "./hook/useOnMountUnsafe";
import { useLocalConfigUser } from "./context/local-config-user.context";
// Layout
import Navbar from "./component/ui/navbar/Navbar";
import Footer from "./component/ui/footer/footer";
import LayoutAuthProvider from "./component/layout-auth-provider";
// * Pages
import HomePage from "./page/home/home-page";
import NotFoundPage from "./page/not-found";
import TicketPage from "./page/ticket/ticket-page";
import ManagerPage from "./page/manager/manager-page";
import CorporationPage from "./page/corporation/corporation-page";
import ArchivePage from "./page/archive/archive-page";
import DashboardPage from "./page/dashboard/dashboard-page";
// import PageTest from "./page/test/page-test";
import LayoutAdmin from "./page/admin/layout-admin";
import AdminPageMessage from "./page/admin/message/message-page";
import AdminPageUser from "./page/admin/users/user-page";
import RMAPage from "./page/admin/rma/rma-page";
// import LoginPage from "./page/login/login-page";


export default function AppRouterComponent() {
  const { keycloak: { authenticated } } = useKeycloak()
  const { setConfig } = useLocalConfigUser()

  useOnMountUnsafe(() => {
    const validateUser = async () => {
      if (authenticated) {
        const response = await Service.userValidate()
        if (!response.error) setConfig(() => response)
      }
    }
    validateUser()
  }, [authenticated])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/"
          element={
            <>
              <Navbar />
              <Outlet />
              <Footer />
            </>
          }
        >
          <Route index element={<HomePage />} />
          {/* <Route path="test" element={<PageTest />} /> */}
          <Route path="ticket" element={<TicketPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="auth" element={
            <LayoutAuthProvider>
              <Outlet />
            </LayoutAuthProvider>
          } >
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="manager" element={<ManagerPage />} />
            <Route path="archive" element={<ArchivePage />} />
            <Route path="corporation" element={<CorporationPage />} />

            <Route path="admin" element={
              <LayoutAdmin>
                <Outlet />
              </LayoutAdmin>
            } >
              <Route path="message" element={<AdminPageMessage />} />
              <Route path="users" element={<AdminPageUser />} />
              <Route path="rma" element={<RMAPage />} />
            </Route>

          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}