import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModalConfirm } from "../../context/toast.context";
import useRmaStatus from "../../hook/useRmaStatus";
import usePagination from "../../hook/usePagination";
import useSelectLimitPagination from "../../component/ui/select-limit-pagination";
import { useOnMountUnsafe } from "../../hook/useOnMountUnsafe";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocalConfigUser } from "../../context/local-config-user.context";
import LayoutPage from "../../component/ui/layout-page-component";
import ManagerView from "./view/manager-view";
import ViewerPdf from "./generating-pdf/viewer-pdf";
import ManagerCreate from "./create/manager-create";
import { Alert, Empty, Pagination, Table, TableColumnsType, Button } from "antd";
import Service from "../../service/sendRequest";
import { STATUS_RMA_CENTER_DEFAULT_DRAFT, STATUS_RMA_CENTER_DEFAULT_SHIPPED } from "../../util/const";
import { iRmaPostViewPage } from "../../util/types";

import { MdEditSquare } from "react-icons/md";
import { IoEyeSharp } from "react-icons/io5";
import { IoPrintSharp } from "react-icons/io5";
import { FiCornerUpLeft } from "react-icons/fi";
import { GrDocumentConfig } from "react-icons/gr";

export default function ManagerPage() {
  const { t } = useTranslation()
  const { funcModalConfig } = useModalConfirm()
  const [params] = useSearchParams()

  // * local config
  const { v_user_config } = useLocalConfigUser()
  const navigate = useNavigate()

  const [openCreateOrEditPost, setOpenModalCreateOrEditPost] = useState(false)
  const [openViewPost, setOpenViewPost] = useState(false)
  const [isPrintRMA, setIsPrintRMA] = useState(false)

  const [postIdSelected, setPostIdSelected] = useState(-1)
  const { limit: limitRMA, ComponentPagination } = useSelectLimitPagination(t)
  const { hashStatus } = useRmaStatus()
  const { data: rmaPost, updatePagination, skip, limit, total, update, loading } = usePagination<iRmaPostViewPage>(Service.getRmaManagerByIdUserAuth, 'id', limitRMA)

  const handlePrintPostRMA = async (id: number, status: number) => {
    if (status === 1) {
      const responseConfirm: boolean = await funcModalConfig(t('page.manager.confirmation.print.title'),
        t('page.manager.confirmation.print'), t('btn.confirmation.accept'), t('btn.confirmation.cancel'))
      if (!responseConfirm) return
    }

    setPostIdSelected(() => id)
    setIsPrintRMA(() => true)
  }

  const validationConfigInitialUser = async () => {
    if (!v_user_config) return false
    const modalAlertConfig: boolean = await funcModalConfig("Alert",
      t('page.manager.confirmation.config_user'),
      t('page.manager.confirmation.go_to_config'), t('btn.confirmation.cancel'))
    if (modalAlertConfig) navigate('/auth/dashboard?v=1')
    return true
  }

  const handleCreatePostRMA = async () => {
    const validate = await validationConfigInitialUser()
    if (validate) return

    setPostIdSelected(() => -1)
    setOpenModalCreateOrEditPost(() => true)
  }

  const handleEditPostRMA = async (id: number, status: number) => {
    // const validate = await validationConfigInitialUser()
    // if (validate) return

    if (status !== 1) return

    setPostIdSelected(() => id);
    setOpenModalCreateOrEditPost(() => true)
  }

  const CorporationColumns: TableColumnsType<iRmaPostViewPage> = useMemo(() => [
    {
      title: t('page.manager.information'),
      dataIndex: "information",
      width: '40%',
    },
    {
      title: t("page.manager.description"),
      dataIndex: "full_description",
      render: ((_, record) => record.full_description.length >= 120 ? `${record.full_description.slice(0, 120)}...` : record.full_description),
      width: '30%',
    },
    {
      title: t("page.manager.status"),
      key: "status",
      render: (_, record) => {
        let status = record?.status ? hashStatus[`${record?.status}`]?.status : '...'
        let typeStatus = 'info' as any
        let className = 'text-blue-900'

        if (status === STATUS_RMA_CENTER_DEFAULT_SHIPPED) {
          typeStatus = "success"
          className = 'text-green-900'
        }
        if (status === STATUS_RMA_CENTER_DEFAULT_DRAFT) {
          typeStatus = "warning"
          className = 'text-yellow-600'
        }
        return (<Alert className={`${className} text-center w-auto`} message={status} type={typeStatus} />)
      },
    },
    {
      title: t("page.manager.create_at"),
      dataIndex: "create_at",
    },
    {
      title: t('message.action'),
      key: 'action',
      // fixed: 'right',
      render: (_, record) => (
        <div className="flex items-center gap-1">
          <Button icon={<MdEditSquare />} disabled={record.status !== 1} onClick={() => handleEditPostRMA(record.id, record.status)} className="text-customColor-exor_blue hover:underline font-semibold" type="dashed">
            {t('message.action.edit')}
          </Button>
          {/* <Tooltip color="blue" placement="bottomRight" title={t('message.tooltip.view')} arrow={true}> */}
          <Button icon={<IoEyeSharp />} onClick={() => { setPostIdSelected(() => record.id); setOpenViewPost(() => true) }} className="text-customColor-exor_blue hover:underline font-semibold" type="dashed">
            {t('message.action.view')}
          </Button>
          {/* </Tooltip> */}
          {/* <Tooltip placement="bottomRight" title={t('message.tooltip.print')} arrow={true}> */}
          <Button icon={<IoPrintSharp />} type="dashed" className="text-sm flex flex-col sm:flex-row hover:underline font-semibold py-7 sm:py-0 " onClick={() => handlePrintPostRMA(record.id, record.status)}>
            {record.status === 1 ? t('message.tooltip.print_send') : t('message.tooltip.print')}
          </Button>
          {/* </Tooltip> */}
        </div>
      ),
    },
  ]
    , [t, hashStatus])

  const componentManagerCreateEdit = useMemo(() =>
    <ManagerCreate
      close={() => { setOpenModalCreateOrEditPost(() => false); update(); }}
      t={t}
      idPostSelected={postIdSelected}
    />, [postIdSelected,])


  useOnMountUnsafe(() => {
    const param = params.get('opt')
    if (!param) return
    if (param === "1") handleCreatePostRMA()
  }, [])

  if (isPrintRMA)
    return (
      <LayoutPage disableDefaultClassName
        className="md:container pt-5 md:px-2 mx-auto"
        title_layout={t('message.tooltip.print')}
        childrenHeader={
          // <div>
          //   <div className="p-2">
          //     <Button onClick={() => { setIsPrintRMA(() => false); setPostIdSelected(() => -1); update() }} type="primary">{t('page.manager.create.back')}</Button>
          //   </div>
          // </div>
          <div className="pb-2 sm:pb-0 px-2 sm:px-0 flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <Button className="font-semibold text-sm px-5 hover:shadow-md" type="primary" onClick={() => { setIsPrintRMA(() => false); setPostIdSelected(() => -1); update() }}  >
              <FiCornerUpLeft />
              {t('page.manager.create.back')}
            </Button>
          </div>
        }
      >
        <ViewerPdf idPostSelected={postIdSelected} />
      </LayoutPage>
    )

  if (openCreateOrEditPost)
    return (
      <LayoutPage
        disableDefaultClassName
        className="md:container pt-5 md:px-2 mx-auto"
        title_layout={postIdSelected === -1 ? t('page.manager.btn.create_post') : t('page.manager.btn.edit_post')}
        childrenHeader={
          <div className="px-2 sm:px-0 flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <Button icon={<FiCornerUpLeft />} className="font-semibold text-sm px-5 hover:shadow-md" type="primary" onClick={() => {
              setOpenModalCreateOrEditPost(() => false);
              setPostIdSelected(() => -1)
            }} >

              {t('page.manager.create.back')}
            </Button>
          </div>
        }
      >
        {componentManagerCreateEdit}
      </LayoutPage>
    )

  return (
    <LayoutPage
      title={t("page.title.manager")}
      className="pt-5 sm:px-2 mx-auto"
      title_layout={t("nav.path.manager")}
      childrenHeader={
        (
          <div className="px-2 sm:px-0 flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            {rmaPost.length !== 0 &&
              <Button
                className="w-full sm:w-auto"
                onClick={() => handleCreatePostRMA()}
                type="primary" >
                <GrDocumentConfig />
                {t('page.manager.btn.create_post')}
              </Button>
            }
            {ComponentPagination}
          </div>
        )
      }>
      {rmaPost.length !== 0 || loading ?
        <>
          <Table
            loading={loading}
            columns={CorporationColumns}
            dataSource={rmaPost}
            pagination={false}
            className="overflow-x-auto shadow-none "
          />
          <Pagination
            showSizeChanger={false}
            onChange={(current) => updatePagination(current)}
            className="my-5 float-end"
            defaultPageSize={limit}
            defaultCurrent={skip}
            current={skip}
            total={total}
            pageSize={limit}
          />
        </>
        :
        <Empty description={t('page.manager.not_found')}>
          <Button
            onClick={() => { handleCreatePostRMA() }}
            type="primary" >{t('page.manager.btn.create_post')}
          </Button>
        </Empty>
      }
      < ManagerView
        hashStatus={hashStatus}
        t={t}
        open={openViewPost}
        onClose={() => { setOpenViewPost(() => false); setPostIdSelected(-1); }}
        idPostSelected={postIdSelected}
      />
    </LayoutPage >
  )
}