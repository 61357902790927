import { useState } from "react"

export default function useOnchange(initialState: any) {
    const [state, setState] = useState(initialState)

    const handleChange = (event: any) => {
        try {
            const { name, value } = event?.target

            if (!name )
                throw Error("field ticket is empty")

            setState((prev: any) => {
                return { ...prev, [name]: value }
            })

        } catch (error: any) {
            console.log('error', error.toString());
        }
    }

    return [state, setState, handleChange]
}